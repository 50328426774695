import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// bootstrap import
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {
  BreadcrumbModule,
  BreadcrumbPageModule,
  CardModule,
} from './components';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ButtonModule } from './components/button/button.module';

@NgModule({
  declarations: [SpinnerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    BreadcrumbModule,
    BreadcrumbPageModule,
    NgbModule,
    NgScrollbarModule,
    ButtonModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    BreadcrumbModule,
    BreadcrumbPageModule,
    SpinnerComponent,
    NgbModule,
    NgScrollbarModule,
    ButtonModule,
  ],
})
export class SharedModule {}
