<header>
  <div class="m-header" [style.display]="this.headerStyle">
    <a class="mobile-menu" id="mobile-collapse1" href="javascript:"
      (click)="this.onNavCollapsedMob.emit()"><span></span></a>
    <a [routerLink]="['/dashboard/default']" class="b-brand">
      <img class="tw-w-[4.5rem]" src="./assets/images/header-backup.png" alt="header-logo">
      <div class="tw-flex tw-flex-col">
        <label class="tw-text-lg tw-font-semibold">สำนักงานเศรษฐกิจการเกษตร</label>
        <label class="tw-text-base">office of Agricultural Econmics</label>
      </div>
    </a>
  </div>
  <!-- <a class="mobile-menu" [ngClass]="{ on: this.menuClass }" id="mobile-header" href="javascript:"
    (click)="toggleMobOption()"><i class="feather icon-more-horizontal"></i></a> -->
  <div class="collapse navbar-collapse navbar-background">
    <app-nav-left class="me-auto" [style.display]="this.headerStyle"></app-nav-left>
    <app-nav-right class="ms-auto"></app-nav-right>
  </div>

  <!-- <div class="collapse navbar-collapse navbar-background tw-grid tw-grid-cols-3">
    <app-nav-left class="tw-col-span-2" [style.display]="this.headerStyle"></app-nav-left>
    <app-nav-right class="tw-ms-auto"></app-nav-right>
  </div> -->
</header>
