import { Component } from '@angular/core';
import Data from 'src/assets/data/Login.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  DataLogin: any = Data;
}
