import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './breadcrumb.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../button/button.module';
import { ExcelService } from '@app/_services/excel.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DateTHPipeModule } from '@app/shared/pipes/dateTHPipe/date-th-pipe.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
@NgModule({
  imports: [CommonModule, RouterModule, ButtonModule, NgxDatatableModule, DateTHPipeModule, MatMenuModule, MatIconModule,
    MatTooltipModule,
    MatButtonModule],
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent],
  providers: [ExcelService],

})
export class BreadcrumbModule { }
