import { Injectable, isDevMode } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import Swal from "sweetalert2";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(private _authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        let newReq = req;

        if (this._authService.accessToken && this._authService.accessToken.length)
        {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
            });
        }

        return next.handle(newReq).pipe(
            catchError((error: (HttpErrorResponse | any)) => {

                if ( error instanceof HttpErrorResponse && error.status === 401 )
                {
                    // Sign out
                    this._authService.signOut();

                    // Reload the app
                    location.reload();
                }

                if (isDevMode())
                {
                    const responseMsg = error.error;
                    if ( responseMsg && responseMsg.statusCode >= 300 )
                    {
                        Swal.fire({
                            width: "100%",
                            title: responseMsg.message + ' (DEV-MODE ALERT)',
                            html: "URL: " + error.url + "<br/>" + "Status: " + error.status + "<br/>" + "Message: " + error.message + "<br/><br/>Description: " + responseMsg.description,
                            icon: 'error',
                            confirmButtonText: 'โอเค'
                        });
                    }
                    
                }

                return throwError(error)
            })
        );
    }
}