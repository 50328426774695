import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './theme/shared/shared.module';
import { GuestComponent } from './theme/layout/guest/guest.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavLogoComponent } from './theme/layout/admin/navigation/nav-logo/nav-logo.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { ToggleFullScreenDirective } from './theme/shared/components/full-screen/toggle-full-screen';
import { FooterComponent } from './theme/layout/admin/footer/footer.component';
import { DataComponent } from './theme/pages/data/data.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HighchartsChartModule } from 'highcharts-angular';
import { HttpClientModule } from '@angular/common/http';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import localeTh from '@angular/common/locales/th';
import { registerLocaleData } from '@angular/common';
import { AuthModule } from './core/auth/auth.module';
import { FuseModule } from './fuse';
import { FuseMockApiModule } from './fuse/lib/mock-api/mock-api.module';
import { mockApiServices } from './mock-api';
import { LoadingBarModule } from './components/loading-bar';
import { LoadingModule } from './shared/service/loading';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
registerLocaleData(localeTh);

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    GuestComponent,
    AdminComponent,
    NavBarComponent,
    NavigationComponent,
    NavLeftComponent,
    NavRightComponent,
    NavContentComponent,
    NavLogoComponent,
    NavCollapseComponent,
    NavGroupComponent,
    NavItemComponent,
    NavSearchComponent,
    ToggleFullScreenDirective,
    FooterComponent,
    DataComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BrowserAnimationsModule,
    NgxDatatableModule,
    HighchartsChartModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    NgbModule,
    AuthModule,
    MatButtonModule,
    MatIconModule,
    LoadingBarModule,
    LoadingModule,

    // Fuse, FuseMockAPI
    FuseModule,
    FuseMockApiModule.forRoot(mockApiServices),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "th-TH" },
    // provider used to create fake backend
    NavigationItem,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
