<ul class="navbar-nav tw-flex tw-flex-col tw-max-h-24 align-items-end tw-m-4">

  <!-- <li class="d-flex flex-column">
    <p class="font-nav p-0 m-0">{{user.firstName}}</p>
    <span class="font-long">{{user.lastName}}</span> -->
  <!-- <a href="javascript:" class="dud-logout" title="Logout">
      <i class="feather icon-log-out"></i>
    </a> -->
  <!-- <div class="dropdown drp-user" ngbDropdown placement="auto">
    </div>
  </li> -->
  <li class="tw-max-h-12">
    <div class="tw-flex max-lg tw-items-center">
      <img src="assets/images/user/avatar-1.jpg" class="img-radius tw-my-1 " alt="User-Profile-Image" />
      <div class="tw-text-gray-300 tw-mx-2 tw-flex tw-flex-col tw-items-start tw-text-lg max-sm" *ngIf="user"><span class="tw-flex tw-text-sm">{{ user.displayName }}</span><span class="tw-text-sm tw-flex">{{ roleName[user.userRole] }}</span></div>
    </div>
  </li>
  <!-- <a ngbDropdownToggle href="javascript:" style="color: #fff;" data-toggle="dropdown"> <img
          src="assets/images/user/avatar-1.jpg" class="img-radius" alt="User-Profile-Image" />
      </a> -->
  <!-- <div class="dropdown-menu dropdown-menu-end profile-notification" ngbDropdownMenu>

        <ul class="pro-body">
          <li>
            <a href="javascript:" class="dropdown-item"><i class="feather icon-settings"></i> Settings</a>
          </li>
          <li>
            <a href="javascript:" class="dropdown-item"><i class="feather icon-user"></i> Profile</a>
          </li>
          <li>
            <a href="javascript:" class="dropdown-item"><i class="feather icon-mail"></i> My Messages</a>
          </li>
          <li>
            <a href="javascript:" class="dropdown-item"><i class="feather icon-lock"></i> Lock Screen</a>
          </li>
        </ul>
      </div> -->
  <li class="tw-ml-2 tw-mb-2">
    <!-- <span class="font-asset">ใช้ไป : 12.0k (6.01%) / 200 k</span> -->
    <div class="dropdown tw-items-end" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:" class="font-asset" data-toggle="dropdown">
        <ng-container *ngIf="userProject.projectLimit !== -1; else adminUsed;">
          ใช้ไป : {{ userProject.projectCount }}
          <!-- ({{ userProject.projectCount/userProject.projectLimit*100 | number:'1.2-2' }}%) --> / {{
          userProject.projectLimit }}
        </ng-container>

        <ng-template #adminUsed>
          ใช้ไป : {{ userProject.projectCount }} / ไม่จำกัด
        </ng-template>
      </a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end notification text-white">
        <div class="notification-head">
          <h6 class="d-inline-block m-b-0" style="font-weight: bolder;">การใช้งาน</h6>
        </div>
        <ul class="notification-body">

          <ng-container *ngIf="userProject.projectLimit !== -1; else adminProgress;">
            <li class="notification">
              <div class="row cl-subtext">
                <div class="col">
                  <p>เนื้อที่ของบัญชี</p>
                </div>
                <div class="col">
                  <p class="float-end">{{ userProject.projectCount }}/{{ userProject.projectLimit }}</p>
                </div>
              </div>
              <div class="progress">
                <div class="progress-bar" style="width: {{ userProject.projectCount/userProject.projectLimit*100 }}%;">
                  {{ userProject.projectCount/userProject.projectLimit*100 | number:'1.2-2' }}%</div>
              </div>
            </li>
          </ng-container>

          <ng-template #adminProgress>
            <li class="notification">
              <div class="row cl-subtext">
                <div class="col">
                  <p>เนื้อที่ของบัญชี</p>
                </div>
                <div class="col">
                  <p class="float-end">{{ userProject.projectCount }}/ไม่จำกัด</p>
                </div>
              </div>
              <div class="progress">
                <div class="progress-bar bgc-success" style="width: 100%;">ไม่จำกัด</div>
              </div>
            </li>
          </ng-template>

        </ul>
        <!-- <div class="notification-footer">
          <div class="row cl-subtext">
            <div class="col">
              <p>ผู้ใช้งาน</p>
            </div>
            <div class="col">
              <p class="float-end">6/10</p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </li>
</ul>
