import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationItem } from '../../../layout/admin/navigation/navigation';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as XLSX from 'xlsx';
import { ExcelService } from '@app/_services/excel.service';
import fs from 'fs';
import Datawarehouse from '../../../../../assets/data/Datawarehouse.json';
import dataCategory from '../../../../../assets/data/dataCategory.json';
import dataRights from '../../../../../assets/data/dataRights.json';
import dataHistory from '../../../../../assets/data/dataHistory.json';
import Projectdata from '../../../../../fake-data/project-data.json';
import { BreadcrumbService } from './breadcrumb.service';
import { Observable, Subject, filter, forkJoin, from, switchMap, take, takeUntil, tap } from 'rxjs';
import { ProjectService } from '@app/theme/pages/project/project.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SocialManageService } from '@app/theme/pages/core/social-manage/social-manage.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { SlmHistoryService } from '@app/theme/pages/social-manage/slm-history/slm-history.service';
import { UserService } from '@app/core/user/user.service';
import { SocialType } from '@app/theme/pages/core/social-manage/social-manage.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() type: string;
  navigation: any;
  breadcrumbList: Array<any> = [];
  navigationList: any[] = [];
  activeLink: string = '';
  otherTitle: string = '';
  rows = [];

  fileNameData = 'Excel_Data';
  fileNameCategory = 'Excel_Category';
  fileNameAuth = 'Excel_Auth';
  fileNameHistory = 'Excel_History';
  fileNameProject = 'Excel_Project';
  ColumnMode = ColumnMode;
  reportData: any[];
  reportCategory: any[];
  reportHistory: any[];
  reportAuth: any[];
  reportProject: any;
  socialTypes: SocialType[] = [];

  CategorySearch: any;
  UserSearch: any;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _router: Router,
    public nav: NavigationItem,
    private titleService: Title,
    public breadcrumbService: BreadcrumbService,
    private _socialService: SocialManageService,
    private _slmHistoryService: SlmHistoryService,
    private _projectService: ProjectService,
    private _userService: UserService,
    private _httpClient: HttpClient,
    private _activatedRoute: ActivatedRoute
  ) {
    this.setBreadcrumb();
    this.type = 'theme2';
  }


  ngOnInit() {

    this._socialService.socials$.pipe(takeUntil(this._unsubscribeAll)).subscribe(types => {
      this.socialTypes = types;
    })

    this._socialService.warehouses$.pipe(takeUntil(this._unsubscribeAll)).subscribe(warehouses => {
      this.reportData = warehouses;
      // console.log('คลัง', this.reportData);
    })

    this._socialService.categorys$
      .pipe(takeUntil(this._unsubscribeAll)).subscribe(categories => {
        this.reportCategory = categories;
        // console.log('หมวดหมู่', this.reportCategory);
      })

    this._slmHistoryService.historys$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((historys) => {
        this.reportHistory = historys;
        // console.log('ประวัติ', this.reportHistory);
      })

    this._userService.users$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((users) => {

        this.reportAuth = users;
        // console.log('ผู้ใช้งาน', this.reportAuth);

      })

    this._projectService.projects$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((users) => {
        this.reportProject = users;
        // console.log('ผู้ใช้งาน', this.reportProject);

      })

  }

  getCategoryName(categories) {
    if (!categories) {
      return "-";
    }

    return categories.map(category => {
      let index = this.reportCategory.findIndex(type => type.categoryFarmId === category.categoryFarmId);
      return index === -1 ? ' ' : this.reportCategory[index].categoryFarmNameTh
    }).join(',');
  }

  getTypeName(id: number) {
    let index = this.socialTypes.findIndex(type => type.socialTypeId === id);
    if (index === -1) {
      return "-";
    }
    else {
      return this.socialTypes[index].socialTypeNameTh;
    }
  }


  setBreadcrumb() {

    let routerUrl: string;
    this._router.events.subscribe((router: any) => {
      if (router.urlAfterRedirects) {
        routerUrl = router.urlAfterRedirects;
        this.activeLink = router.url;
      } else if (router.routerEvent) {
        routerUrl = router.routerEvent.urlAfterRedirects;
        this.activeLink = router.routerEvent.url;
      }

      if (routerUrl && typeof routerUrl === 'string') {
        this.breadcrumbList.length = 0;
      }

      this.filterNavigation(this.activeLink);
    });
  }

  filterNavigation(activeLink) {
    let result: any;
    let title = 'Welcome';
    this.nav.items$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((items) => {

        items.forEach(function (a) {

          if (activeLink.includes("signed-in-redirect")) {
            result = [
              {
                id: 'dashboard',
                title: 'แดชบอร์ด',
                type: 'item',
                url: '/dashboard/list-dashboard',
                icon: 'feather icon-user',
                classes: 'nav-item',
                buttonGroup: {
                  refresh: false,
                  edit: false,
                  export: false,
                }
              },
            ];
          }

          if (a.type === 'item' && 'url' in a && activeLink.includes(a.url)) {
            result = [
              {
                id: 'id' in a ? a.id : 0,
                url: 'url' in a ? a.url : false,
                title: a.title,
                breadcrumbs: 'breadcrumbs' in a ? a.breadcrumbs : true,
                type: a.type,
                buttonGroup: 'buttonGroup' in a ? a.buttonGroup : false,
                menuGroup: 'menuGroup' in a ? a.menuGroup : '',
                paramPage: 'paramPage' in a ? a.paramPage : '',
              },
            ];
            //console.log(result);

            title = a.title;
          } else if (a.type === 'item' && 'children' in a) {
            a.children.forEach(function (b) {
              // magic thing

              let tempUrl = b.url;

              if (b.url.includes(':')) {

                tempUrl = b.url.replace(
                  ':id',
                  parseInt(activeLink.slice(b.url.lastIndexOf(':')))
                );
              }

              if (b.type === 'item' && 'url' in b && activeLink.includes(tempUrl)) {
                result = [
                  {
                    id: 'id' in a ? a.id : 0,
                    url: 'url' in a ? a.url : false,
                    title: a.title,
                    breadcrumbs: 'breadcrumbs' in a ? a.breadcrumbs : true,
                    type: a.type,
                    buttonGroup: 'buttonGroup' in a ? a.buttonGroup : false,
                    menuGroup: 'menuGroup' in a ? a.menuGroup : '',
                    paramPage: 'paramPage' in a ? a.paramPage : '',
                  },
                  {
                    id: 'id' in b ? b.id : 0,
                    url: 'url' in b ? tempUrl : false,
                    title: b.title,
                    breadcrumbs: 'breadcrumbs' in b ? b.breadcrumbs : true,
                    type: b.type,
                    buttonGroup: 'buttonGroup' in b ? b.buttonGroup : false,
                    menuGroup: 'menuGroup' in b ? b.menuGroup : '',
                    paramPage: 'paramPage' in b ? b.paramPage : '',
                  },
                ];
                title = b.title;
              }
            });
          } else {
            if (a.type === 'group' && 'children' in a) {
              a.children.forEach(function (b) {
                if (b.type === 'item' && 'url' in b && activeLink.includes(b.url)) {
                  result = [
                    {
                      id: 'id' in b ? b.id : 0,
                      url: 'url' in b ? b.url : false,
                      title: b.title,
                      breadcrumbs: 'breadcrumbs' in b ? b.breadcrumbs : true,
                      type: b.type,
                      buttonGroup: 'buttonGroup' in b ? b.buttonGroup : false,
                      menuGroup: 'menuGroup' in b ? b.menuGroup : '',
                      paramPage: 'paramPage' in b ? b.paramPage : '',
                    },
                  ];
                  title = b.title;
                } else {
                  if (b.type === 'collapse' && 'children' in b) {
                    b.children.forEach(function (c) {
                      if (c.type === 'item' && 'url' in c && activeLink.includes(c.url)) {
                        result = [
                          {
                            id: 'id' in b ? b.id : 0,
                            url: 'url' in b ? b.url : false,
                            title: b.title,
                            breadcrumbs: 'breadcrumbs' in b ? b.breadcrumbs : true,
                            type: b.type,
                            buttonGroup: 'buttonGroup' in b ? b.buttonGroup : false,
                            menuGroup: 'menuGroup' in b ? b.menuGroup : '',
                            paramPage: 'paramPage' in b ? b.paramPage : '',
                          },
                          {
                            id: 'id' in c ? c.id : 0,
                            url: 'url' in c ? c.url : false,
                            title: c.title,
                            breadcrumbs: 'breadcrumbs' in c ? c.breadcrumbs : true,
                            type: c.type,
                            buttonGroup: 'buttonGroup' in c ? c.buttonGroup : false,
                            menuGroup: 'menuGroup' in c ? c.menuGroup : '',
                            paramPage: 'paramPage' in c ? c.paramPage : '',
                          },
                        ];
                        title = c.title;
                      } else if (
                        c.type === 'item' &&
                        'url' in c &&
                        !activeLink.includes(c.url) &&
                        'children' in c
                      ) {
                        c.children.forEach(function (d) {
                          if (
                            d.type === 'item' &&
                            'url' in d &&
                            activeLink.includes(a.url)
                          ) {
                            result = [
                              {
                                id: 'id' in b ? b.id : 0,
                                url: 'url' in b ? b.url : false,
                                title: b.title,
                                breadcrumbs:
                                  'breadcrumbs' in b ? b.breadcrumbs : true,
                                type: b.type,
                                buttonGroup:
                                  'buttonGroup' in b ? b.buttonGroup : false,
                                menuGroup: 'menuGroup' in b ? b.menuGroup : '',
                                paramPage: 'paramPage' in b ? b.paramPage : '',
                              },
                              {
                                id: 'id' in c ? c.id : 0,
                                url: 'url' in c ? c.url : false,
                                title: c.title,
                                breadcrumbs:
                                  'breadcrumbs' in c ? c.breadcrumbs : true,
                                type: c.type,
                                buttonGroup:
                                  'buttonGroup' in c ? c.buttonGroup : false,
                                menuGroup: 'menuGroup' in c ? c.menuGroup : '',
                                paramPage: 'paramPage' in c ? c.paramPage : '',
                              },
                              {
                                id: 'id' in d ? d.id : 0,
                                url: 'url' in d ? d.url : false,
                                title: d.title,
                                breadcrumbs:
                                  'breadcrumbs' in d ? d.breadcrumbs : true,
                                type: d.type,
                                buttonGroup:
                                  'buttonGroup' in d ? d.buttonGroup : false,
                                menuGroup: 'menuGroup' in d ? d.menuGroup : '',
                                paramPage: 'paramPage' in d ? d.paramPage : '',
                              },
                            ];
                            title = d.title;
                          }
                        });
                      }
                    });
                  }
                }
              });
            }
          }
        });
      });


    this.navigationList = result;

    // ตั้งค่าชื่อ title : ถ้าไม่มี title และมีค่า title จาก breadcrumbService ส่งมา
    if (
      this.navigationList &&
      this.navigationList[this.navigationList.length - 1].title === undefined
    ) {
      let lastList = this.navigationList.length - 1;

      this.breadcrumbService.titleBreadcrumb$
        .pipe(
          take(1),
          takeUntil(this._unsubscribeAll)
        )
        .subscribe((res) => {
          console.log(res);

          if (res) {
            this.navigationList[lastList].title = res.title;
            title = res.title;

            // Note: This is a temporary solution for fixed about when refresh page the name of the current page has gone??.
            // So 'sessionStorage' will keep the current page name until changing the page is.
            // ** If have a good solution than this, feel free to change it!
            let currentPageName = {
              routeName: this.navigationList[lastList].url,
              pageName: res.title,
            };

            sessionStorage.setItem(
              'currentPageName',
              JSON.stringify(currentPageName)
            );
          } else {
            let getPageName = JSON.parse(
              sessionStorage.getItem('currentPageName')
            );
            if (getPageName && getPageName.routeName === this.navigationList[lastList].url) {
              this.navigationList[lastList].title = getPageName.pageName;
              title = getPageName.pageName;
            }
          }

          this.titleService.setTitle(title + ' | สำนักงานเศรษฐกิจการเกษตร');

        });
    }
    else {
      this.titleService.setTitle(title + ' | สำนักงานเศรษฐกิจการเกษตร');
    }
  }

  clickHandler(btnType, breadcrumb) {
    console.log(btnType, breadcrumb);

    switch (btnType) {
      case 'refresh':
        this._projectService.project$
          .pipe(
            take(1),
            takeUntil(this._unsubscribeAll),
            switchMap((res) =>
              forkJoin([
                this._projectService.getProjectById(res.projectId),
                this._projectService.getProjectKeyword(res.projectId),
              ]).pipe(
                // already get project id, keywords
                switchMap(() =>
                  forkJoin([
                    this._projectService.getProjectOvertimes(),
                    this._projectService.getProjectSentiments(),
                    this._projectService.getProjectTopMentions(),
                    this._projectService.getProjectTextRank(),
                    this._projectService.getPostByProjectId(),
                  ])
                )
              )
            )
          )
          .subscribe();
        break;
      case 'edit':
        /* let param = breadcrumb.paramPage ? breadcrumb.paramPage : '';
        this._router.navigate(['/' + breadcrumb.menuGroup + '/edit/' + param]); */
        this._projectService.project$
          .pipe(take(1), takeUntil(this._unsubscribeAll))
          .subscribe((res) => {
            this._router.navigate(['/project/' + res.projectId + '/edit']);
          });
        break;
      case 'exportXlsx':
        switch (breadcrumb.id) {
          case 'data':
            let params_data = {
              username: localStorage.getItem('username').toLowerCase(),
              search: localStorage.getItem('query' || '')
            }
            console.log("params:", params_data);
            let link_data = document.createElement('a');
            link_data.download = 'filename';
            link_data.href = `${environment.apiMain}/api/v1.0` + '/export/warehouse?u=' + params_data.username + '&k=' + (params_data.search == null ? '' : params_data.search) + '&ft=xlsx';
            link_data.click();
            break;
          case 'list-category':
            let params = {
              username: localStorage.getItem('username').toLowerCase(),
              search: localStorage.getItem('query' || '')
            }
            console.log("params:", params);
            let link = document.createElement('a');
            link.download = 'filename';
            link.href = `${environment.apiMain}/api/v1.0` + '/export/farm_category?u=' + params.username + '&k=' + (params.search == null ? '' : params.search) + '&ft=xlsx';
            link.click();
            break;
          case 'manageper':
            let manageper = {
              username: localStorage.getItem('username').toLowerCase(),
              search: localStorage.getItem('query' || '')
            }
            console.log("params_:", manageper);
            let link_manageper = document.createElement('a');
            link_manageper.download = 'filename';
            link_manageper.href = `${environment.apiMain}/api/v1.0` + '/export/user?u=' + manageper.username + '&k=' + (manageper.search == null ? '' : manageper.search) + '&ft=xlsx';
            link_manageper.click();
            break;
          case 'history':

            let params_history = {
              username: localStorage.getItem('username').toLowerCase(),
              search: localStorage.getItem('query' || '')
            }
            console.log("params:", params_history);
            let link_history = document.createElement('a');
            link_history.download = 'filename';
            link_history.href = `${environment.apiMain}/api/v1.0` + '/export/history?u=' + params_history.username + '&k=' + (params_history.search == null ? '' : params_history.search) + '&ft=xlsx';
            link_history.click();
            break;
          case 'projects':
            let params_project = {
              username: localStorage.getItem('username').toLowerCase(),
              search: localStorage.getItem('query' || '')
            }
            console.log("params:", params_project);
            let link_project = document.createElement('a');
            link_project.download = 'filename';
            link_project.href = `${environment.apiMain}/api/v1.0` + '/export/project?u=' + params_project.username + '&k=' + (params_project.search == null ? '' : params_project.search) + '&ft=xlsx';
            link_project.click();
            break;
        }
        // this.excelService.exportAsExcelFile(this.setBreadcrumb, 'sample');
        break;
      case 'exportCsv':
        switch (breadcrumb.id) {
          case 'data':
            let params_data = {
              username: localStorage.getItem('username').toLowerCase(),
              search: localStorage.getItem('query' || '')
            }
            console.log("params:", params_data);
            let link_data = document.createElement('a');
            link_data.download = 'filename';
            link_data.href = `${environment.apiMain}/api/v1.0` + '/export/warehouse?u=' + params_data.username + '&k=' + (params_data.search == null ? '' : params_data.search) + '&ft=csv';
            link_data.click();
            break;
          case 'list-category':
            let params = {
              username: localStorage.getItem('username').toLowerCase(),
              search: localStorage.getItem('query' || '')
            }
            console.log("params:", params);
            let link = document.createElement('a');
            link.download = 'filename';
            link.href = `${environment.apiMain}/api/v1.0` + '/export/farm_category?u=' + params.username + '&k=' + (params.search == null ? '' : params.search) + '&ft=csv';
            link.click();
            break;
          case 'manageper':
            let manageper = {
              username: localStorage.getItem('username').toLowerCase(),
              search: localStorage.getItem('query' || '')
            }
            console.log("params_:", manageper);
            let link_manageper = document.createElement('a');
            link_manageper.download = 'filename';
            link_manageper.href = `${environment.apiMain}/api/v1.0` + '/export/user?u=' + manageper.username + '&k=' + (manageper.search == null ? '' : manageper.search) + '&ft=csv';
            link_manageper.click();
            break;
          case 'history':

            let params_history = {
              username: localStorage.getItem('username').toLowerCase(),
              search: localStorage.getItem('query' || '')
            }
            console.log("params:", params_history);
            let link_history = document.createElement('a');
            link_history.download = 'filename';
            link_history.href = `${environment.apiMain}/api/v1.0` + '/export/history?u=' + params_history.username + '&k=' + (params_history.search == null ? '' : params_history.search) + '&ft=csv';
            link_history.click();
            break;
          case 'projects':
            let params_project = {
              username: localStorage.getItem('username').toLowerCase(),
              search: localStorage.getItem('query' || '')
            }
            console.log("params:", params_project);
            let link_project = document.createElement('a');
            link_project.download = 'filename';
            link_project.href = `${environment.apiMain}/api/v1.0` + '/export/project?u=' + params_project.username + '&k=' + (params_project.search == null ? '' : params_project.search) + '&ft=csv';
            link_project.click();
            console.log(link_manageper.href);
            break;

        }

        // this.excelService.exportAsExcelFile(this.setBreadcrumb, 'sample');
        break;
      default:
        break;
    }
  }
}
