<!-- <pre>
  {{ navigationList | json }}
</pre>
 -->

<div *ngIf="navigationList" class="breadcrumb-wrapper">
  <ng-container *ngFor="let breadcrumb of navigationList; let last = last">
    <div class="page-header" *ngIf="last && breadcrumb.breadcrumbs !== false">
      <div class="page-block">
        <div class="page-header-title">
          <div class="row">
            <div class="col-7">
              <i class="fa-sharp fa-solid fa-house me-2"></i>
              <ng-container *ngFor="let breadcrumb2 of navigationList; let last2 = last">
                <span class="me-2">{{ breadcrumb2.title }}</span>
                <span *ngIf="!last2" class="me-2">></span>
                <h4 *ngIf="last2" class="breadcrumb-font">
                  {{ breadcrumb2.title ? breadcrumb2.title : otherTitle }}
                </h4>
              </ng-container>
            </div>
            <div *ngIf="last && breadcrumb.buttonGroup"
              class="col-5 d-flex flex-row justify-content-end align-items-end">
              <app-button *ngIf="breadcrumb.buttonGroup.refresh" buttonTitle="รีเฟรช" buttonClass="btn-base btn-blue"
                buttonImagePath="assets/icon/icon-refresh.png"
                (click)="clickHandler('refresh', breadcrumb)"></app-button>
              <app-button *ngIf="breadcrumb.buttonGroup.edit" buttonTitle="แก้ไขโปรเจกต์"
                buttonClass="btn-base btn-warning" buttonImagePath="assets/icon/icon-edit.png"
                (click)="clickHandler('edit', breadcrumb)"></app-button>
              <div class="btn-group" role="group" *ngIf="breadcrumb.buttonGroup.export && !(breadcrumbService.hideBreadcrumb$ | async)">
                <app-button mat-button [matMenuTriggerFor]="menu" buttonTitle="ส่งออก"
                  buttonClass="btn-base btn-success">
                </app-button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="clickHandler('exportXlsx', breadcrumb)">ไฟล์(.xlsx)</button>
                  <button mat-menu-item (click)="clickHandler('exportCsv', breadcrumb)">ไฟล์(.csv)</button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>


<!-- <table id="excel-table">
  <tr>
    <th>Id</th>
    <th>Name</th>
    <th>Username</th>
    <th>Email</th>
  </tr>
  <tr *ngFor="let item of userList">
    <td>{{item.id}}</td>
    <td>{{item.name}}</td>
    <td>{{item.username}}</td>
    <td>{{item.email}}</td>
  </tr>
</table> -->

<table id="table-data" style="display: none;">
  <tr>
    <th>รหัสคลังข้อมูล</th>
    <th>ชื่อคลังข้อมูล</th>
    <th>หมวดหมู่คลังข้อมูล</th>
    <th>แพลตฟอร์ม</th>
    <th>เพิ่มโดย</th>
    <th>วันที่เพิ่มข้อมูล</th>
  </tr>
  <tr *ngFor="let item of reportData">
    <td>{{item.warehouseId}}</td>
    <td>{{item.warehouseName}}</td>
    <td>{{getCategoryName(item.categories)}}</td>
    <td>{{getTypeName(item.socialTypeId)}}</td>
    <td>{{item.createdBy}}</td>
    <td>{{ item.createdTime | dateTHPipe: 'dt' }}</td>
  </tr>
</table>
<table id="table-category" style="display: none;">
  <tr>
    <th>รหัสหมวดหมู่</th>
    <th>ชื่อภาษาไทย</th>
    <th>ชื่อภาษาอังกฤษ</th>
    <th>รายละเอียดหมวดหมู่</th>
    <th>เพิ่มโดย</th>
    <th>วันที่เพิ่มข้อมูล</th>
  </tr>
  <tr *ngFor="let item of reportCategory">
    <td>{{item.categoryFarmId}}</td>
    <td>{{item.categoryFarmNameTh}}</td>
    <td>{{item.categoryFarmNameEng}}</td>
    <td>{{item.categoryFarmDetail}}</td>
    <td>{{item.createdBy}}</td>
    <td>{{ item.createdTime | dateTHPipe: 'dt' }}</td>
  </tr>
</table>
<table id="table-manageper" style="display: none;">
  <tr>
    <th>รหัสผู้ใช้งาน</th>
    <th>ชื่อที่ใช้แสดง</th>
    <th>อีเมลผู้เข้าใช้งาน/th>
    <th>สิทธิ์การเข้าใช้งาน</th>
    <th>เพิ่มโดย</th>
    <th>วันที่เพิ่มข้อมูล</th>
  </tr>
  <tr *ngFor="let item of reportAuth">
    <td>{{item.userId}}</td>
    <td>{{item.displayName}}</td>
    <td>{{item.username}}</td>
    <td>{{item.userRole}}</td>
    <td>{{item.createdBy}}</td>
    <td>{{ item.createdTime | dateTHPipe: 'dt' }}</td>
  </tr>
</table>
<table id="table-history" style="display: none;">
  <tr>
    <th>รหัสการเข้าใช้งาน</th>
    <th>เข้าใช้งานเมนู</th>
    <th>การกระทำในเมนู</th>
    <th>ชื่อผู้ใช้งาน</th>
    <th>อีเมลผู้เข้าใช้งาน</th>
    <th>วันที่เข้าใช้งาน</th>
  </tr>
  <tr *ngFor="let item of reportHistory">
    <td>{{item.logId}}</td>
    <td>{{item.logMenu}}</td>
    <td>{{item.logActivity}}</td>
    <td>{{item.logTitle}}</td>
    <td>{{item.logUserName}}</td>
    <td>{{ item.createdTime | dateTHPipe: 'dt' }}</td>
  </tr>
</table>
<table id="table-projects" style="display: none;">
  <tr>
    <th>รหัสโปรเจกต์</th>
    <th>ชื่อโปรเจกต์</th>
    <th>รายละเอียดโปรเจกต์</th>
    <th>วันที่เริ่มโปรเจกต์</th>
    <th>วันที่สิ้นสุดโปรเจกต์</th>
    <th>เครดิตของโปรเจกต์</th>
    <th>เพิ่มโดย</th>
    <th>วันที่เพิ่มข้อมูล</th>
  </tr>
  <tr *ngFor="let item of reportProject">
    <td>{{item.projectId}}</td>
    <td>{{item.projectName}}</td>
    <td>{{item.projectDetail}}</td>
    <td>{{item.projectAnalysisStart}}</td>
    <td>{{item.projectAnalysisEnd}}</td>
    <td>{{item.projectCreditCount}}</td>
    <td>{{item.createdBy}}</td>
    <td>{{ item.createdTime | dateTHPipe: 'dt' }}</td>
  </tr>
</table>