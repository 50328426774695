import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  menuGroup?: 'data' | 'project' | 'social' | 'manage' | 'history';
  paramPage?: string;
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  buttonGroup?: {
    refresh: boolean;
    edit: boolean;
    export: boolean;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'dashboard',
    title: 'แดชบอร์ด',
    type: 'item',
    url: '/dashboard/list-dashboard',
    icon: 'feather icon-user',
    classes: 'nav-item',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: false,
    },
  },
  // {
  //   id: 'data',
  //   title: 'คลังข้อมูล',
  //   type: 'item',
  //   url: '/data/list',
  //   icon: 'feather icon-trending-up',
  //   classes: 'nav-item',
  //   buttonGroup: {
  //     refresh: false,
  //     edit: false,
  //     export: true,
  //   },
  //   children: [
  //     {
  //       id: 'data-import',
  //       title: 'นำเข้าฐานข้อมูล',
  //       type: 'item',
  //       url: '/data/import',
  //       target: false,
  //       breadcrumbs: true,
  //       buttonGroup: {
  //         refresh: false,
  //         edit: false,
  //         export: false,
  //       },
  //     },
  //   ],
  // },
  {
    id: 'projects',
    title: 'โปรเจกต์',
    type: 'item',
    url: '/project',
    icon: 'feather icon-server',
    classes: 'nav-item',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: true,
    },
    children: [
      {
        id: 'project-list',
        title: 'สร้างโปรเจกต์',
        type: 'item',
        url: '/project/new',
        target: false,
        breadcrumbs: true,
        buttonGroup: {
          refresh: false,
          edit: false,
          export: false,
        },
      },
      {
        id: 'project-edit',
        title: 'แก้ไขโปรเจกต์',
        type: 'item',
        url: '/project/:id/edit',
        target: false,
        breadcrumbs: true,
        buttonGroup: {
          refresh: false,
          edit: false,
          export: false,
        },
      },
      {
        id: 'project-inside',
        type: 'item',
        url: '/project/inside/:id',
        target: false,
        breadcrumbs: true,
        buttonGroup: {
          refresh: true,
          edit: true,
          // export: true,
        },
        children: [
          {
            id: 'data-inside-view',
            type: 'item',
            url: '/project/inside/:id/view',
            target: false,
            breadcrumbs: true,
            buttonGroup: {
              refresh: false,
              edit: false,
              export: false,
            },
          },
        ],
      },
    ],
  },
  // {
  //   id: 'group-project',
  //   type: 'group',
  //   children: [
  //     {
  //       id: 'projects',
  //       title: 'โปรเจกต์',
  //       type: 'collapse',
  //       icon: 'feather icon-trending-up',
  //       children: [
  //         {
  //           id: 'list-all',
  //           title: 'โปรเจกต์',
  //           type: 'item',
  //           menuGroup: 'project',
  //           url: '/project/list-all',
  //           target: false,
  //           breadcrumbs: true,
  //           buttonGroup: {
  //             refresh: false,
  //             edit: false,
  //             export: false,
  //           },
  //         },
  //         {
  //           id: 'create',
  //           title: 'สร้างโปรเจกต์',
  //           type: 'item',
  //           menuGroup: 'project',
  //           url: '/project/create',
  //           target: false,
  //           hidden: true,
  //           breadcrumbs: true,
  //           buttonGroup: {
  //             refresh: false,
  //             edit: false,
  //             export: false,
  //           },
  //         },
  //         {
  //           id: 'rice',
  //           title: 'ราคาข้าวตกต่ำ',
  //           type: 'item',
  //           menuGroup: 'project',
  //           paramPage: 'rice',
  //           url: '/project/list/rice',
  //           target: false,
  //           breadcrumbs: true,
  //           buttonGroup: {
  //             refresh: true,
  //             edit: true,
  //             export: true,
  //           },
  //           children: [
  //             {
  //               id: 'rice-edit',
  //               title: 'แก้ไขโปรเจกต์',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'rice',
  //               url: '/project/edit/rice',
  //               target: false,
  //               hidden: true,
  //               breadcrumbs: true,
  //               buttonGroup: {
  //                 refresh: false,
  //                 edit: false,
  //                 export: false,
  //               },
  //             },
  //             {
  //               id: 'rice-inside',
  //               title: 'inside',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'rice',
  //               url: '/project/inside/rice',
  //               target: false,
  //               breadcrumbs: true,
  //               buttonGroup: {
  //                 refresh: true,
  //                 edit: true,
  //                 export: true,
  //               },
  //             },
  //             {
  //               id: 'rice-inside-view',
  //               title: 'Lorem ipsum dolor sit amet consectetur. Etiam.',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'rice',
  //               url: '/project/inside-view',
  //               target: false,
  //               hidden: true,
  //               breadcrumbs: true,
  //             },
  //           ],
  //         },
  //         {
  //           id: 'robber',
  //           title: 'ราคายางพารา',
  //           type: 'item',
  //           menuGroup: 'project',
  //           paramPage: 'robber',
  //           url: '/project/list/robber',
  //           target: false,
  //           breadcrumbs: true,
  //           buttonGroup: {
  //             refresh: true,
  //             edit: true,
  //             export: true,
  //           },
  //           children: [
  //             {
  //               id: 'robber-edit',
  //               title: 'แก้ไขโปรเจกต์',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'robber',
  //               url: '/project/edit/robber',
  //               target: false,
  //               hidden: true,
  //               breadcrumbs: true,
  //               buttonGroup: {
  //                 refresh: false,
  //                 edit: false,
  //                 export: false,
  //               },
  //             },
  //             {
  //               id: 'robber-inside',
  //               title: 'inside',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'robber',
  //               url: '/project/inside/robber',
  //               target: false,
  //               breadcrumbs: true,
  //               buttonGroup: {
  //                 refresh: true,
  //                 edit: true,
  //                 export: true,
  //               },
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    id: 'group-social-manage',
    type: 'group',
    children: [
      {
        id: 'social-manage',
        title: 'การจัดการข้อมูล',
        type: 'collapse',
        icon: 'feather icon-settings',
        children: [
          {
            id: 'data',
            title: 'คลังข้อมูล',
            type: 'item',
            url: '/data/list',
            target: false,
            breadcrumbs: true,
            buttonGroup: {
              refresh: false,
              edit: false,
              export: true,
            },
            children: [
              {
                id: 'data-import',
                title: 'นำเข้าฐานข้อมูล',
                type: 'item',
                url: '/data/import',
                target: false,
                breadcrumbs: true,
                buttonGroup: {
                  refresh: false,
                  edit: false,
                  export: false,
                },
              },
            ],
          },
          // {
          //   id: 'list-social',
          //   title: 'ข้อมูลทางสังคม',
          //   type: 'item',
          //   url: '/social-manage/list-social',
          //   target: false,
          //   breadcrumbs: true,
          //   buttonGroup: {
          //     refresh: false,
          //     edit: false,
          //     export: true,
          //   },
          // },
          {
            id: 'list-category',
            title: 'หมวดหมู่',
            type: 'item',
            url: '/social-manage/list-category',
            target: false,
            breadcrumbs: true,
            buttonGroup: {
              refresh: false,
              edit: false,
              export: true,
            },
          },
          {
            id: 'list-word-farm',
            title: 'คำศัพท์เกี่ยวกับเกษตร',
            type: 'item',
            url: '/social-manage/list-word-farm',
            target: false,
            breadcrumbs: true,
            buttonGroup: {
              refresh: false,
              edit: false,
              export: false,
            },
          }
        ],
      },
    ],
  },
  {
    id: 'schedule',
    title: 'กำหนดเวลา',
    type: 'item',
    url: '/social-manage/list-schedule',
    icon: 'feather icon-clock',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: false,
    },
  },
  {
    id: 'manageper',
    title: 'จัดการสิทธิ์เข้าใช้งาน',
    type: 'item',
    url: '/social-manage/list-rights',
    icon: 'feather icon-user',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: true,
    },
  },
  {
    id: 'history',
    title: 'ประวัติการเข้าใช้งาน',
    type: 'item',
    url: '/social-manage/list-history',
    icon: 'feather icon-activity',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: true,
    },
  },
  {
    id: 'logout',
    title: 'ออกจากระบบ',
    type: 'item',
    url: '/sign-out',

    icon: 'feather icon-log-out',
    target: false,
    breadcrumbs: false,
  },
];

const NavigationItemsAdmin = [
  {
    id: 'dashboard',
    title: 'แดชบอร์ด',
    type: 'item',
    url: '/dashboard/list-dashboard',
    icon: 'feather icon-user',
    classes: 'nav-item',
    breadcrumbs: true,
    buttonGroup: {
      refresh: false,
      edit: false,
      export: false,
    },
  },
  // {
  //   id: 'data',
  //   title: 'คลังข้อมูล',
  //   type: 'item',
  //   url: '/data/list',
  //   icon: 'feather icon-trending-up',
  //   classes: 'nav-item',
  //   buttonGroup: {
  //     refresh: false,
  //     edit: false,
  //     export: true,
  //   },
  //   children: [
  //     {
  //       id: 'data-import',
  //       title: 'นำเข้าฐานข้อมูล',
  //       type: 'item',
  //       url: '/data/import',
  //       target: false,
  //       breadcrumbs: true,
  //       buttonGroup: {
  //         refresh: false,
  //         edit: false,
  //         export: false,
  //       },
  //     },
  //   ],
  // },
  {
    id: 'projects',
    title: 'โปรเจกต์',
    type: 'item',
    url: '/project',
    icon: 'feather icon-server',
    classes: 'nav-item',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: false,
    },
    children: [
      {
        id: 'data-import',
        title: 'สร้างโปรเจกต์',
        type: 'item',
        url: '/project/new',
        target: false,
        breadcrumbs: true,
        buttonGroup: {
          refresh: false,
          edit: false,
          export: false,
        },
      },
    ],
  },
  // {
  //   id: 'group-project',
  //   type: 'group',
  //   children: [
  //     {
  //       id: 'projects',
  //       title: 'โปรเจกต์',
  //       type: 'collapse',
  //       icon: 'feather icon-trending-up',
  //       children: [
  //         {
  //           id: 'list-all',
  //           title: 'โปรเจกต์',
  //           type: 'item',
  //           menuGroup: 'project',
  //           url: '/project/list-all',
  //           target: false,
  //           breadcrumbs: true,
  //           buttonGroup: {
  //             refresh: false,
  //             edit: false,
  //             export: false,
  //           },
  //         },
  //         {
  //           id: 'create',
  //           title: 'สร้างโปรเจกต์',
  //           type: 'item',
  //           menuGroup: 'project',
  //           url: '/project/create',
  //           target: false,
  //           hidden: true,
  //           breadcrumbs: true,
  //           buttonGroup: {
  //             refresh: false,
  //             edit: false,
  //             export: false,
  //           },
  //         },
  //         {
  //           id: 'rice',
  //           title: 'ราคาข้าวตกต่ำ',
  //           type: 'item',
  //           menuGroup: 'project',
  //           paramPage: 'rice',
  //           url: '/project/list/rice',
  //           target: false,
  //           breadcrumbs: true,
  //           buttonGroup: {
  //             refresh: true,
  //             edit: true,
  //             export: true,
  //           },
  //           children: [
  //             {
  //               id: 'rice-edit',
  //               title: 'แก้ไขโปรเจกต์',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'rice',
  //               url: '/project/edit/rice',
  //               target: false,
  //               hidden: true,
  //               breadcrumbs: true,
  //               buttonGroup: {
  //                 refresh: false,
  //                 edit: false,
  //                 export: false,
  //               },
  //             },
  //             {
  //               id: 'rice-inside',
  //               title: 'inside',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'rice',
  //               url: '/project/inside/rice',
  //               target: false,
  //               breadcrumbs: true,
  //               buttonGroup: {
  //                 refresh: true,
  //                 edit: true,
  //                 export: true,
  //               },
  //             },
  //             {
  //               id: 'rice-inside-view',
  //               title: 'Lorem ipsum dolor sit amet consectetur. Etiam.',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'rice',
  //               url: '/project/inside-view',
  //               target: false,
  //               hidden: true,
  //               breadcrumbs: true,
  //             },
  //           ],
  //         },
  //         {
  //           id: 'robber',
  //           title: 'ราคายางพารา',
  //           type: 'item',
  //           menuGroup: 'project',
  //           paramPage: 'robber',
  //           url: '/project/list/robber',
  //           target: false,
  //           breadcrumbs: true,
  //           buttonGroup: {
  //             refresh: true,
  //             edit: true,
  //             export: true,
  //           },
  //           children: [
  //             {
  //               id: 'robber-edit',
  //               title: 'แก้ไขโปรเจกต์',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'robber',
  //               url: '/project/edit/robber',
  //               target: false,
  //               hidden: true,
  //               breadcrumbs: true,
  //               buttonGroup: {
  //                 refresh: false,
  //                 edit: false,
  //                 export: false,
  //               },
  //             },
  //             {
  //               id: 'robber-inside',
  //               title: 'inside',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'robber',
  //               url: '/project/inside/robber',
  //               target: false,
  //               breadcrumbs: true,
  //               buttonGroup: {
  //                 refresh: true,
  //                 edit: true,
  //                 export: true,
  //               },
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    id: 'group-social-manage',
    type: 'group',
    children: [
      {
        id: 'social-manage',
        title: 'การจัดการข้อมูล',
        type: 'collapse',
        icon: 'feather icon-settings',
        classes: 'nav-item',
        children: [
          {
            id: 'list-category',
            title: 'หมวดหมู่',
            type: 'item',
            url: '/social-manage/list-category',
            target: false,
            breadcrumbs: true,
            buttonGroup: {
              refresh: false,
              edit: false,
              export: true,
            },
          },
          {
            id: 'data',
            title: 'คลังข้อมูล',
            type: 'item',
            url: '/data/list',
            target: false,
            breadcrumbs: true,
            buttonGroup: {
              refresh: false,
              edit: false,
              export: true,
            },
            children: [
              {
                id: 'data-import',
                title: 'นำเข้าฐานข้อมูล',
                type: 'item',
                url: '/data/import',
                target: false,
                breadcrumbs: true,
                buttonGroup: {
                  refresh: false,
                  edit: false,
                  export: false,
                },
              },
            ],
          },
          {
            id: 'list-word-farm',
            title: 'คำศัพท์เกี่ยวกับเกษตร',
            type: 'item',
            url: '/social-manage/list-word-farm',
            target: false,
            breadcrumbs: true,
            buttonGroup: {
              refresh: false,
              edit: false,
              export: false,
            },
          },
          // {
          //   id: 'list-social',
          //   title: 'ข้อมูลทางสังคม',
          //   type: 'item',
          //   url: '/social-manage/list-social',
          //   target: false,
          //   breadcrumbs: true,
          //   buttonGroup: {
          //     refresh: false,
          //     edit: false,
          //     export: true,
          //   },
          // },
        ],
      },
    ],
  },
  {
    id: 'schedule',
    title: 'กำหนดเวลา',
    type: 'item',
    url: '/social-manage/list-schedule',
    icon: 'feather icon-clock',
    classes: 'nav-item',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: false,
    },
  },
  {
    id: 'manageper',
    title: 'จัดการสิทธิ์เข้าใช้งาน',
    type: 'item',
    url: '/social-manage/list-rights',
    icon: 'feather icon-user',
    classes: 'nav-item',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: true,
    },
  },
  {
    id: 'history',
    title: 'ประวัติการเข้าใช้งาน',
    type: 'item',
    url: '/social-manage/list-history',
    icon: 'feather icon-activity',
    classes: 'nav-item',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: true,
    },
  },
  {
    id: 'logout',
    title: 'ออกจากระบบ',
    type: 'item',
    url: '/sign-out',
    icon: 'feather icon-log-out',
    target: false,
    breadcrumbs: false,
  },
];

const NavigationItemsManager = [
  {
    id: 'dashboard',
    title: 'แดชบอร์ด',
    type: 'item',
    url: '/dashboard/list-dashboard',
    icon: 'feather icon-user',
    classes: 'nav-item',
    breadcrumbs: true,
    buttonGroup: {
      refresh: false,
      edit: false,
      export: false,
    },
  },
  {
    id: 'projects',
    title: 'โปรเจกต์',
    type: 'item',
    url: '/project',
    icon: 'feather icon-server',
    classes: 'nav-item',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: false,
    },
    children: [
      {
        id: 'data-import',
        title: 'สร้างโปรเจกต์',
        type: 'item',
        url: '/project/new',
        target: false,
        breadcrumbs: true,
        buttonGroup: {
          refresh: false,
          edit: false,
          export: false,
        },
      },
    ],
  },
  {
    id: 'history',
    title: 'ประวัติการเข้าใช้งาน',
    type: 'item',
    url: '/social-manage/list-history',
    icon: 'feather icon-activity',
    classes: 'nav-item',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: true,
    },
  },
  {
    id: 'logout',
    title: 'ออกจากระบบ',
    type: 'item',
    url: '/sign-out',
    icon: 'feather icon-log-out',
    target: false,
    breadcrumbs: false,
  },
];

const NavigationItemsUser = [
  {
    id: 'dashboard',
    title: 'แดชบอร์ด',
    type: 'item',
    url: '/dashboard/list-dashboard',
    icon: 'feather icon-user',
    classes: 'nav-item',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: false,
    },
  },
  // {
  //   id: 'data',
  //   title: 'คลังข้อมูล',
  //   type: 'item',
  //   url: '/data/list',
  //   icon: 'feather icon-trending-up',
  //   classes: 'nav-item',
  //   buttonGroup: {
  //     refresh: false,
  //     edit: false,
  //     export: true,
  //   },
  //   children: [
  //     {
  //       id: 'data-import',
  //       title: 'นำเข้าฐานข้อมูล',
  //       type: 'item',
  //       url: '/data/import',
  //       target: false,
  //       breadcrumbs: true,
  //       buttonGroup: {
  //         refresh: false,
  //         edit: false,
  //         export: false,
  //       },
  //     },
  //   ],
  // },
  {
    id: 'projects',
    title: 'โปรเจกต์',
    type: 'item',
    url: '/project',
    icon: 'feather icon-server',
    classes: 'nav-item',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: false,
    },
    children: [
      {
        id: 'data-import',
        title: 'สร้างโปรเจกต์',
        type: 'item',
        url: '/project/new',
        target: false,
        breadcrumbs: true,
        buttonGroup: {
          refresh: false,
          edit: false,
          export: false,
        },
      },
    ],
  },
  // {
  //   id: 'group-project',
  //   type: 'group',
  //   children: [
  //     {
  //       id: 'projects',
  //       title: 'โปรเจกต์',
  //       type: 'collapse',
  //       icon: 'feather icon-trending-up',
  //       children: [
  //         {
  //           id: 'list-all',
  //           title: 'โปรเจกต์',
  //           type: 'item',
  //           menuGroup: 'project',
  //           url: '/project/list-all',
  //           target: false,
  //           breadcrumbs: true,
  //           buttonGroup: {
  //             refresh: false,
  //             edit: false,
  //             export: false,
  //           },
  //         },
  //         {
  //           id: 'create',
  //           title: 'สร้างโปรเจกต์',
  //           type: 'item',
  //           menuGroup: 'project',
  //           url: '/project/create',
  //           target: false,
  //           hidden: true,
  //           breadcrumbs: true,
  //           buttonGroup: {
  //             refresh: false,
  //             edit: false,
  //             export: false,
  //           },
  //         },
  //         {
  //           id: 'rice',
  //           title: 'ราคาข้าวตกต่ำ',
  //           type: 'item',
  //           menuGroup: 'project',
  //           paramPage: 'rice',
  //           url: '/project/list/rice',
  //           target: false,
  //           breadcrumbs: true,
  //           buttonGroup: {
  //             refresh: true,
  //             edit: true,
  //             export: true,
  //           },
  //           children: [
  //             {
  //               id: 'rice-edit',
  //               title: 'แก้ไขโปรเจกต์',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'rice',
  //               url: '/project/edit/rice',
  //               target: false,
  //               hidden: true,
  //               breadcrumbs: true,
  //               buttonGroup: {
  //                 refresh: false,
  //                 edit: false,
  //                 export: false,
  //               },
  //             },
  //             {
  //               id: 'rice-inside',
  //               title: 'inside',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'rice',
  //               url: '/project/inside/rice',
  //               target: false,
  //               breadcrumbs: true,
  //               buttonGroup: {
  //                 refresh: true,
  //                 edit: true,
  //                 export: true,
  //               },
  //             },
  //             {
  //               id: 'rice-inside-view',
  //               title: 'Lorem ipsum dolor sit amet consectetur. Etiam.',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'rice',
  //               url: '/project/inside-view',
  //               target: false,
  //               hidden: true,
  //               breadcrumbs: true,
  //             },
  //           ],
  //         },
  //         {
  //           id: 'robber',
  //           title: 'ราคายางพารา',
  //           type: 'item',
  //           menuGroup: 'project',
  //           paramPage: 'robber',
  //           url: '/project/list/robber',
  //           target: false,
  //           breadcrumbs: true,
  //           buttonGroup: {
  //             refresh: true,
  //             edit: true,
  //             export: true,
  //           },
  //           children: [
  //             {
  //               id: 'robber-edit',
  //               title: 'แก้ไขโปรเจกต์',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'robber',
  //               url: '/project/edit/robber',
  //               target: false,
  //               hidden: true,
  //               breadcrumbs: true,
  //               buttonGroup: {
  //                 refresh: false,
  //                 edit: false,
  //                 export: false,
  //               },
  //             },
  //             {
  //               id: 'robber-inside',
  //               title: 'inside',
  //               type: 'item',
  //               menuGroup: 'project',
  //               paramPage: 'robber',
  //               url: '/project/inside/robber',
  //               target: false,
  //               breadcrumbs: true,
  //               buttonGroup: {
  //                 refresh: true,
  //                 edit: true,
  //                 export: true,
  //               },
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    id: 'history',
    title: 'ประวัติการเข้าใช้งาน',
    type: 'item',
    url: '/social-manage/list-history',
    icon: 'feather icon-activity',
    classes: 'nav-item',
    buttonGroup: {
      refresh: false,
      edit: false,
      export: true,
    },
  },
  {
    id: 'logout',
    title: 'ออกจากระบบ',
    type: 'item',
    url: '/sign-out',
    icon: 'feather icon-log-out',
    target: false,
    breadcrumbs: false,
  },
];

import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationItem {


  private _items = new BehaviorSubject<NavigationItem[] | any>(NavigationItems);

  get items$() {
    return this._items.asObservable();
  }


  get() {
    return NavigationItems;
  }

  getManager() {
    return NavigationItemsManager;
  }

  getAdmin() {
    return NavigationItemsAdmin;
  }

  getUser() {
    return NavigationItemsUser;
  }

  constructor(private router: Router, private route: ActivatedRoute) {}

  logout() {
    this.router.navigate(['/sign-out']);
  }
}
