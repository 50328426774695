import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbPageComponent } from './breadcrumb-page.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../button/button.module';

@NgModule({
  imports: [CommonModule, RouterModule, ButtonModule],
  declarations: [BreadcrumbPageComponent],
  exports: [BreadcrumbPageComponent],
})
export class BreadcrumbPageModule {}
