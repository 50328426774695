import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthUtils } from './auth.utils';
import { UserService } from '../user/user.service';
import { User } from '../user/user.types';
import jwtDecode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _authenticated: boolean = false;

  constructor(
    private _httpClient: HttpClient, private _activatedRoute: ActivatedRoute, private _userService: UserService
  ) {
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  signIn(credentials: { username: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post(environment.apiMain + '/api/v1.0/login', credentials).pipe(
      map((response: any) => response.data),
      switchMap((response: any) => {


        this.accessToken = response.token.split(" ")[1];

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  signWithSSO(credentials: { username: string; password: string }): Observable<any> {
    return this._httpClient.post(environment.apiMain + '/api/v1.0/sso-login', credentials).pipe(
      map((response: any) => response.data),
      switchMap((response: any) => {

        this.accessToken = response.token.split(" ")[1];

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  authenticationSSO(token: string): Observable<any> {
    return this._httpClient.get<any>(`${environment.apiSSOAuth}/oauth2/rest/token/info`, {
      params: {
        access_token: token
      },
      headers: {
        'X-OAUTH-IDENTITY-DOMAIN-NAME': 'oauthdomain'
      }
    });
  }

  signOut() {
    return this._httpClient.get<any>(`${environment.apiMain}/api/v1.0/logout`).pipe(
      tap((response) => {
        of(response.status)
      })
    ).subscribe(() => {
      this._authenticated = false;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('sso_token');
    });
  }

  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post(`${environment.apiMain}/api/v1.0/forgot_password`, {
      email
    });
  }

  resetPassword(credentials: { email: string, old_password: string; new_password: string; confirm_password: string }): Observable<any> {
    return this._httpClient.post(`${environment.apiMain}/api/v1.0/update_password`, credentials);
  }

  check(): Observable<boolean> {

    if (localStorage.getItem("sso_token") && localStorage.getItem("sso_token") != "null") {
      const token = localStorage.getItem("sso_token");
      /* return this.authenticationSSO(token).pipe(
        catchError(() => {
          return of(false);
        }),
        switchMap((response: any) => {
          console.log(response);
          
          const username = (jwtDecode(token) as any).sub;
          return this.signWithSSO({
            username,
            password: token
          }).pipe(
            switchMap((response) => {
              return of(!!response)
            })
          )
        }
        )); */

      //console.log(response);

      const username = (jwtDecode(token) as any).sub;
      return this.signWithSSO({
        username,
        password: token
      }).pipe(
        catchError(() => {
          return of(false);
        }),
        switchMap((response) => {
          return of(!!response)
        })
      )

    }

    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken || this.accessToken === undefined) {
      return of(false);
    }

    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    return this._userService.get().pipe(
      switchMap((response: User) => {

        this._authenticated = true;

        //this._userService.user = response;

        return of(true);
      })
    );

  }
}
