import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions, PreloadAllModules } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { GuestComponent } from './theme/layout/guest/guest.component';
import { NoAuthGuard } from './core/auth/guards/no-auth.guard';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { InitialDataResolver } from "@app/app.resolver";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/list-dashboard',
    pathMatch: 'full',
  },
  
  {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard/list-dashboard'},


  {
    path: '',
    component: GuestComponent,
    children: [
        {path: 'sign-in', loadChildren: () => import('src/app/demo/pages/authentication/authentication.module').then(m => m.AuthenticationModule)},
        {path: 'sso', loadChildren: () => import('src/app/modules/auth/sso/sso.module').then(m => m.SsoModule)}
    ]
  },

  {
    path: '',
    canMatch: [NoAuthGuard],
    component: GuestComponent,
    children: [
        {path: 'forgot-password', loadChildren: () => import('src/app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)}
    ]
  },

  {
    path: 'tw-playground',
    component: GuestComponent,
    children: [
        {path: '', loadChildren: () => import('src/app/modules/playground/tw/tw.module').then(m => m.TwModule)}
    ]
  },

  {
    path: '',
    canMatch: [AuthGuard],
    component: GuestComponent,
    children: [
        {path: 'sign-out', loadChildren: () => import('src/app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)}
    ]
  },

  {
    path: '',
    component: AdminComponent,
    canMatch: [AuthGuard],
    resolve: {
      data: InitialDataResolver
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'project',
        loadChildren: () =>
          import('./theme/pages/project/project.module').then(
            (m) => m.ProjectModule
          ),
      },
      {
        path: 'social-manage',
        loadChildren: () =>
          import('./theme/pages/social-manage/social-manage.module').then(
            (m) => m.SocialModule
          ),
      },
      {
        path: 'data',
        loadChildren: () =>
          import('./theme/pages/data/data.module').then((m) => m.DataModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./theme/pages/dashboard/dashboard.module').then((m) => m.DashBoardModule),
      },
      // {
      //   path: 'dashboard',
      //   loadComponent: () => import('./demo/dashboard/dashboard.component'),
      // },
      // {
      //   path: 'signin',
      //   loadComponent: () =>
      //     import(
      //       './demo/pages/authentication/auth-signin/auth-signin.component'
      //     ),
      // },
      // {
      //   path: 'sample-page',
      //   loadComponent: () =>
      //     import('./demo/extra/sample-page/sample-page.component'),
      // },
    ],
  }
];

const routerConfig: ExtraOptions = {
  preloadingStrategy       : PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
