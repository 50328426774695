<div class="">
  <a [routerLink]="['/dashboard/default/']" class="b-brand">
    <div class="b-toggle">
      <img src="./assets/icon/menu/toggle-1.png" alt="" />
    </div>
  </a>
  <a
    href="javascript:"
    class="mobile-menu"
    [ngClass]="{ on: navCollapsed }"
    id="mobile-collapse"
    (click)="navCollapse()"
    ><span></span
  ></a>
</div>
