<div id="main-search" class="main-search">
  <div class="input-group">
    <input
      type="text"
      id="m-search"
      class="form-control"
      placeholder="Search . . ."
      [style.width]="searchWidthString"
    />
    <a
      href="javascript:"
      class="input-group-append search-close"
      (click)="searchOff()"
    >
      <i class="feather icon-x input-group-text"></i>
    </a>
    <span
      class="input-group-append search-btn btn btn-primary"
      (click)="searchOn()"
    >
      <i class="feather icon-search input-group-text"></i>
    </span>
  </div>
</div>
