import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationResponse } from '@app/_models/types';
import { environment } from '@environments/environment';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  scan,
  Subject,
  switchMap,
  take,
  takeLast,
  tap,
  throwError,
} from 'rxjs';
import { Warehouse } from '../core/social-manage/social.types';
import {
  PlatformProject,
  Project,
  ProjectKeyword,
  ProjectPagination,
  ProjectPosts,
  ProjectOvertimes,
  ProjectSentiments,
  ProjectTextRank,
  ProjectTopMentions,
} from './project.types';
import { DateTHPipe } from '@app/shared/pipes/dateTHPipe/date-th-pipe.pipe';
import { DatePipe } from "@angular/common";
import { Post, PostPaging } from "@app/shared/service/posts/posts.types";

@Injectable({
  providedIn: 'root',
})
export class ProjectService {

  startDate = new Date((new Date()).setMonth(new Date().getMonth() - 1));
  endDate = new Date((new Date()).setMonth(new Date().getMonth()));


  private _pagination: BehaviorSubject<ProjectPagination | null> =
    new BehaviorSubject(null);
  private _projects: BehaviorSubject<Project[] | null> = new BehaviorSubject<
    Project[] | null
  >(null);
  private _project: BehaviorSubject<Project | null> =
    new BehaviorSubject<Project | null>(null);
  private _posts: BehaviorSubject<ProjectPosts | null> =
    new BehaviorSubject<ProjectPosts | null>(null);

  private _keywords: BehaviorSubject<string[] | null> =
    new BehaviorSubject<string[] | null>(null);

  private _overtimes: BehaviorSubject<ProjectOvertimes | null> =
    new BehaviorSubject<ProjectOvertimes | null>(null);
  private _sentiments: BehaviorSubject<ProjectSentiments | null> =
    new BehaviorSubject<ProjectSentiments | null>(null);
  private _topmentions: BehaviorSubject<ProjectTopMentions[] | null> =
    new BehaviorSubject<ProjectTopMentions[] | null>(null);
  private _textrank: BehaviorSubject<ProjectTextRank | null> =
    new BehaviorSubject<ProjectTextRank | null>(null);
  private _projectPosts: BehaviorSubject<Post[] | null> = new BehaviorSubject<Post[] | null>(null);
  private _projectPostPaging: BehaviorSubject<PostPaging | null> = new BehaviorSubject<PostPaging | null>(null);

  get projectPosts$(): Observable<Post[]> {
    return this._projectPosts.asObservable();
  }

  get projectPostPaging$(): Observable<PostPaging> {
    return this._projectPostPaging.asObservable();
  }


  get pagination$(): Observable<ProjectPagination> {
    return this._pagination.asObservable();
  }

  get projects$(): Observable<Project[]> {
    return this._projects.asObservable();
  }

  get project$(): Observable<Project> {
    return this._project.asObservable();
  }

  get posts$(): Observable<ProjectPosts> {
    return this._posts.asObservable();
  }

  get keywords$(): Observable<string[]> {
    return this._keywords.asObservable();
  }

  get overtimes$(): Observable<ProjectOvertimes> {
    return this._overtimes.asObservable();
  }

  get sentiments$(): Observable<ProjectSentiments> {
    return this._sentiments.asObservable();
  }

  get topmentions$(): Observable<ProjectTopMentions[]> {
    return this._topmentions.asObservable();
  }

  get textrank$(): Observable<ProjectTextRank> {
    return this._textrank.asObservable();
  }

  constructor(private _httpClient: HttpClient) { }

  get(
    search: string = '',
    page: number = 1,
    limit: number = 10,
    sort: string = 'createdTime',
    username: string = '',
    date: string = ''
  ): Observable<{ pagination: ProjectPagination; projects: Project[] }> {
    return this._httpClient
      .get<PaginationResponse>(`${environment.apiMain}/api/v1.0/projects`, {
        params: {
          q: search,
          page: page.toString(),
          limit: limit.toString(),
          sort,
          username,
          date
        },
      })
      .pipe(
        /* switchMap((response) => {

          console.log(response);
          
          const projects: Project[] = response.data;

          // empty project but want to go into current router page must return value exactly to resolver return
          if (projects.length == 0) {
            const ret = {
              pagination: {
                length: 0,
                size: 0,
                page: 0,
                lastPage: 0,
                startIndex: 0,
                endIndex: 0,
              },
              projects: [],
            };
            this._pagination.next(ret.pagination);
            this._projects.next(ret.projects);

            return of(ret);
          }

          // Fetch keywords and platforms for each project
          const requests = projects.map((project) =>
            forkJoin([
              this._httpClient.get<ProjectKeyword[]>(
                `${environment.apiMain}/api/v1.0/projects/${project.projectId}/keywords`
              ),
              this._httpClient.get<PlatformProject[]>(
                `${environment.apiMain}/api/v1.0/projects/${project.projectId}/platforms`
              ),
            ])
          );

          return forkJoin(requests).pipe(
            map((responses: [ProjectKeyword[], PlatformProject[]][]) => {
              projects.forEach((project, index) => {
                project.keywords = responses[index][0];
                project.platforms = responses[index][1];
              });

              const ret: {
                pagination: ProjectPagination;
                projects: Project[];
              } = {
                pagination: {
                  length: response.totalItems,
                  size: limit,
                  page: response.currentPage - 1,
                  lastPage: response.totalPages,
                  startIndex:
                    response.currentPage > 1
                      ? (response.currentPage - 1) * limit
                      : 0,
                  endIndex: Math.min(
                    response.currentPage * limit,
                    response.totalItems
                  ),
                },
                projects,
              };

              this._pagination.next(ret.pagination);
              this._projects.next(ret.projects);

              return ret;
            })
          );
        }) */
        map(response => {

          const projects: Project[] = response.data;

          // empty project but want to go into current router page must return value exactly to resolver return
          if (projects.length == 0) {
            const ret = {
              pagination: {
                length: 0,
                size: 0,
                page: 0,
                lastPage: 0,
                startIndex: 0,
                endIndex: 0,
              },
              projects: [],
            };
            this._pagination.next(ret.pagination);
            this._projects.next(ret.projects);
            return ret;
          }

          const ret: {
            pagination: ProjectPagination;
            projects: Project[];
          } = {
            pagination: {
              length: response.totalItems,
              size: limit,
              page: response.currentPage - 1,
              lastPage: response.totalPages,
              startIndex:
                response.currentPage > 1
                  ? (response.currentPage - 1) * limit
                  : 0,
              endIndex: Math.min(
                response.currentPage * limit,
                response.totalItems
              ),
            },
            projects,
          };

          this._pagination.next(ret.pagination);
          this._projects.next(ret.projects);

          return ret;
        })
      );
  }

  getProjectById(id): Observable<Project> {
    return this._httpClient
      .get<Project>(`${environment.apiMain}/api/v1.0/projects/${id}`)
      .pipe(
        tap((project) => {
          this._project.next(project);
        }));
  }

  getProjectKeyword(id, type: string = 'M'): Observable<ProjectKeyword[]> {
    return this._httpClient.get<ProjectKeyword[]>(`${environment.apiMain}/api/v1.0/projects/${id}/keywords${type === null ? ('') : (type !== '' ? '?keywordType=' + type : '')}`)
      .pipe(tap(response => {
        const keywords = response.map(keyword => keyword.keywordProjectName);
        const uniqueKeyword = Array.from(new Set(keywords.map(a => a)))
          .map(keyword => {
            return keywords.find(a => a === keyword)
          })
        this._keywords.next(uniqueKeyword);
      }));
  }

  getProjectPlatform(id): Observable<PlatformProject[]> {
    return this._httpClient.get<PlatformProject[]>(`${environment.apiMain}/api/v1.0/projects/${id}/platforms`);
  }

  getProjectOvertimes(type = 'mention', keywords: string[] | null = null, startDateStr: string = "", endDateStr: string = ""): Observable<ProjectOvertimes> {

    const keywords$ = keywords === null || keywords?.length == 0 ? this.keywords$.pipe(take(1)) : of(keywords);

    return combineLatest([keywords$, this.project$]).pipe(
      take(1),
      switchMap(([keywords, project]) => {
        if (startDateStr.length == 0) {
          startDateStr = new DatePipe('th-TH').transform(new Date(project.projectAnalysisStart || this.startDate).toISOString(), 'yyyy-MM-dd');
        }

        if (endDateStr.length == 0) {
          endDateStr = new DatePipe('th-TH').transform(new Date(project.projectAnalysisEnd || this.endDate).toISOString(), 'yyyy-MM-dd');
        }

        return this._httpClient.get<ProjectOvertimes>(`${environment.apiMain}/api/v1.0/projects/${project.projectId}/overtimes`, {
          params: {
            type: type,
            keywords,
            startDate: startDateStr,
            endDate: endDateStr,
          },
        });
      }),
      tap((overtimes) => {
        this._overtimes.next(overtimes);
      })
    );
  }

  /* getOvertimes(type, keywords: string[], startDate: string, endDate: string): Observable<ProjectOvertimes> {
    return this.project$.pipe(
      take(1),
      switchMap(project => this._httpClient.get<ProjectOvertimes>(`${environment.apiMain}/api/v1.0/projects/${project.projectId}/overtimes`,
        {
          params: {
            type,
            keywords,
            startDate,
            endDate,
          },
        })),
      map(v => {
        console.log(v);
        return v;
      })
    )
  } */

  getById(
    id: string,
    type: string = 'mention',
    startDate: string = '',
    endDate: string = '',
    keywords: string = ''
  ) {
    /* return this._httpClient
      .get<Project>(`${environment.apiMain}/api/v1.0/projects/${id}`)
      .pipe(
        switchMap((project) => {
          const requests = forkJoin([
            this._httpClient
              .get<ProjectKeyword[]>(
                `${environment.apiMain}/api/v1.0/projects/${project.projectId}/keywords`
              )
              .pipe(catchError(() => of(null))),
            this._httpClient
              .get<PlatformProject[]>(
                `${environment.apiMain}/api/v1.0/projects/${project.projectId}/platforms`
              )
              .pipe(catchError(() => of(null))),
          ]);

          return forkJoin(requests).pipe(
            map((responses: [ProjectKeyword[], PlatformProject[]][]) => {
              project.keywords = responses[0][0];
              project.platforms = responses[0][1];

              this._project.next(project);

              return project;
            })
          );
        }),
        switchMap((project) => {
          const getDate = new Date();
          const getCurrentDate =
            getDate.getFullYear() +
            '-' +
            (getDate.getMonth() + 1) +
            '-' +
            getDate.getDate();
          const getPreviousDate =
            getDate.getFullYear() +
            '-' +
            getDate.getMonth() +
            '-' +
            getDate.getDate();
          const previousDate = new DateTHPipe().transform(
            getPreviousDate,
            'dn'
          );
          const currentDate = new DateTHPipe().transform(getCurrentDate, 'dn');

          const getKeywords = [];
          let projectKeywords = '';
          const mapItem = new Map();
          for (const item of project.keywords) {
            if (!mapItem.has(item.keywordProjectName)) {
              mapItem.set(item.keywordProjectName, true); // set any value to Map
              getKeywords.push(item.keywordProjectName);
            }
          }
          projectKeywords = getKeywords.toLocaleString();

          const requests = forkJoin([
            this.getPostByProjectId(
              id,
              1,
              'engagement',
              keywords === '' ? projectKeywords : keywords,
              startDate === '' ? previousDate : startDate,
              endDate === '' ? currentDate : endDate
            ),
            this._httpClient
              .get<ProjectOvertimes>(
                `${environment.apiMain}/api/v1.0/projects/${project.projectId}/overtimes`,
                {
                  params: {
                    type: type,
                    keywords: keywords === '' ? projectKeywords : keywords,
                    startDate: startDate === '' ? previousDate : startDate,
                    endDate: endDate === '' ? currentDate : endDate,
                  },
                }
              )
              .pipe(catchError(() => of(null))),
            this._httpClient
              .get<ProjectSentiments>(
                `${environment.apiMain}/api/v1.0/projects/${project.projectId}/sentiment`,
                {
                  params: {
                    keywords: keywords === '' ? projectKeywords : keywords,
                    startDate: startDate === '' ? previousDate : startDate,
                    endDate: endDate === '' ? currentDate : endDate,
                  },
                }
              )
              .pipe(catchError(() => of(null))),
            this._httpClient
              .get<ProjectTextRank>(
                `${environment.apiMain}/api/v1.0/projects/${project.projectId}/text_rank`,
                {
                  params: {
                    keywords: keywords === '' ? projectKeywords : keywords,
                    startDate: startDate,
                    endDate: endDate,
                  },
                }
              )
              .pipe(catchError(() => of(null))),
            this._httpClient
              .get<ProjectTopMentions[]>(
                `${environment.apiMain}/api/v1.0/projects/${project.projectId}/top_mentions`,
                {
                  params: {
                    keywords: keywords === '' ? projectKeywords : keywords,
                    startDate: startDate === '' ? previousDate : startDate,
                    endDate: endDate === '' ? currentDate : endDate,
                  },
                }
              )
              .pipe(catchError(() => of(null))),
          ]);

          return forkJoin(requests).pipe(
            map(
              (
                responses: [
                  ProjectPosts,
                  ProjectOvertimes,
                  ProjectSentiments,
                  ProjectTextRank,
                  ProjectTopMentions[]
                ][]
              ) => {
                project.posts = responses[0][0];
                project.overtimes = responses[0][1];
                project.sentiments = responses[0][2];
                project.textRank = responses[0][3];
                project.topMentions = responses[0][4];

                this._project.next(project);

                return project;
              }
            )
          );
        }),
        switchMap((project) => {
          if (!project) {
            return throwError(
              () => 'Could not found project with id of ' + id + '!'
            );
          }

          return of(project);
        })
      ); */
    return of({})
  }

  getPostByProjectId(
    type: string = 'all',
    page: number = 1,
    sort: string = 'engagement',
    keywords: string | string[] = '',
    startDateStr: string = '',
    endDateStr: string = ''
  ) {

    const keywords$ = keywords === null || keywords?.length == 0 ? this.keywords$.pipe(take(1)) : of(keywords);

    return combineLatest([keywords$, this.project$]).pipe(
      take(1),
      switchMap(([keywords, project]) => {
        if (startDateStr.length == 0) {
          startDateStr = new DatePipe('th-TH').transform(new Date(project.projectAnalysisStart || this.startDate).toISOString(), 'yyyy-MM-dd');
        }

        if (endDateStr.length == 0) {
          endDateStr = new DatePipe('th-TH').transform(new Date(project.projectAnalysisEnd || this.endDate).toISOString(), 'yyyy-MM-dd');
        }

        return this._httpClient.get<{ data: Post[], paging: PostPaging }>(`${environment.apiMain}/api/v1.0/projects/${project.projectId}/posts`, {
          params: {
            type,
            sort: sort,
            page: page,
            startDate: startDateStr,
            endDate: endDateStr,
            keywords: keywords,
          },
        });
      }),
      tap((responses) => {
        this._projectPosts.next(responses.data);
        this._projectPostPaging.next(responses.paging);
      })
    );

  }

  getProjectSentiments(type: string = 'all', keywords: string[] | null = null, startDateStr: string = "", endDateStr: string = ""): Observable<ProjectSentiments> {
    const keywords$ = keywords === null || keywords?.length == 0 ? this.keywords$.pipe(take(1)) : of(keywords);

    return combineLatest([keywords$, this.project$]).pipe(
      take(1),
      switchMap(([keywords, project]) => {
        if (startDateStr.length == 0) {
          startDateStr = new DatePipe('th-TH').transform(new Date(project.projectAnalysisStart || this.startDate).toISOString(), 'yyyy-MM-dd');
        }

        if (endDateStr.length == 0) {
          endDateStr = new DatePipe('th-TH').transform(new Date(project.projectAnalysisEnd || this.endDate).toISOString(), 'yyyy-MM-dd');
        }

        return this._httpClient.get<ProjectSentiments>(`${environment.apiMain}/api/v1.0/projects/${project.projectId}/sentiment`, {
          params: {
            type,
            keywords,
            startDate: startDateStr,
            endDate: endDateStr,
          },
        });
      }),
      tap((sentiments) => {
        this._sentiments.next(sentiments);
      })
    );
  }


  getProjectTextRank(type: string = 'all', keywords: string[] | null = null, startDateStr: string = "", endDateStr: string = ""): Observable<ProjectTextRank> {

    const keywords$ = keywords === null || keywords?.length == 0 ? this.keywords$.pipe(take(1)) : of(keywords);

    return combineLatest([keywords$, this.project$]).pipe(
      take(1),
      switchMap(([keywords, project]) => {
        if (startDateStr.length == 0) {
          startDateStr = new DatePipe('th-TH').transform(new Date(project.projectAnalysisStart || this.startDate).toISOString(), 'yyyy-MM-dd');
        }

        if (endDateStr.length == 0) {
          endDateStr = new DatePipe('th-TH').transform(new Date(project.projectAnalysisEnd || this.endDate).toISOString(), 'yyyy-MM-dd');
        }

        return this._httpClient.get<ProjectTextRank>(`${environment.apiMain}/api/v1.0/projects/${project.projectId}/text_rank`, {
          params: {
            type,
            keywords,
            startDate: startDateStr,
            endDate: endDateStr,
          },
        });
      }),
      tap((textranks) => {
        this._textrank.next(textranks);
      })
    );

  }


  getProjectTopMentions(type: string = 'all', keywords: string[] | null = null, startDateStr: string = "", endDateStr: string = ""): Observable<ProjectTopMentions[]> {

    const keywords$ = keywords === null || keywords?.length == 0 ? this.keywords$.pipe(take(1)) : of(keywords);

    return combineLatest([keywords$, this.project$]).pipe(
      take(1),
      switchMap(([keywords, project]) => {
        if (startDateStr.length == 0) {
          startDateStr = new DatePipe('th-TH').transform(new Date(project.projectAnalysisStart).toISOString(), 'yyyy-MM-dd');
        }

        if (endDateStr.length == 0) {
          endDateStr = new DatePipe('th-TH').transform(new Date(project.projectAnalysisEnd).toISOString(), 'yyyy-MM-dd');
        }

        return this._httpClient.get<ProjectTopMentions[]>(`${environment.apiMain}/api/v1.0/projects/${project.projectId}/top_mentions`, {
          params: {
            type,
            keywords,
            startDate: startDateStr,
            endDate: endDateStr,
          },
        });
      }),
      tap((topmentions) => {
        this._topmentions.next(topmentions);
      })
    );

  }

  getNextPosts() {
    return this.projectPostPaging$.pipe(
      take(1),
      filter(url => url && url.next != null),
      switchMap(url => this._httpClient.get<{ data: Post[], paging: PostPaging }>(url.next).pipe(
        tap(response => {
          this._projectPosts.next(response.data);
          this._projectPostPaging.next(response.paging);
        }
        )))
    )
  }

  /**
   *
   *
   * Update project
   * remove current keywords
   * remove platform
   * creaate keywords
   * create platform
   */
  update(
    project: Project,
    keywords: ProjectKeyword[],
    warehouses: Warehouse[]
  ): Observable<Project> {
    return this._httpClient
      .put(
        `${environment.apiMain}/api/v1.0/projects/${project.projectId}`,
        project
      )
      .pipe(
        concatMap((pj: Project) =>
          this._httpClient
            .delete(
              `${environment.apiMain}/api/v1.0/projects/${project.projectId}/keywords`
            )
            .pipe(
              concatMap(() =>
                this._httpClient
                  .delete(
                    `${environment.apiMain}/api/v1.0/projects/${project.projectId}/platforms`
                  )
                  .pipe(
                    concatMap(() =>
                      this._httpClient
                        .post(
                          `${environment.apiMain}/api/v1.0/projects/${project.projectId}/keywords`,
                          keywords
                        )
                        .pipe(
                          concatMap(() =>
                            this._httpClient
                              .post(
                                `${environment.apiMain}/api/v1.0/projects/${project.projectId}/platforms`,
                                warehouses
                              )
                              .pipe(map(() => pj))
                          )
                        )
                    )
                  )
              )
            )
        )
      );
  }

  /**
   *
   * @param id
   * @param updates
   * updates เป็น Array Object ฟิลด์ที่ต้องการอัปเดตใน DB
   * {
   *  projectStatus: 'A',
   *  ...
   * }
   * @returns
   */
  updateProjectField(id, updates) {
    return this.projects$.pipe(
      take(1),
      switchMap((projects) =>
        this._httpClient
          .patch(`${environment.apiMain}/api/v1.0/projects/${id}`, updates)
          .pipe(
            map((updateProject: Project) => {
              const index = projects.findIndex(
                (project) => project.projectId == id
              );
              if (index !== -1) {
                projects[index] = { ...projects[index], ...updateProject };
                this._projects.next(projects);
              }
              return updateProject;
            })
          )
      )
    );
  }

  create(
    project: Project,
    keywords: ProjectKeyword[],
    warehouses: Warehouse[]
  ) {
    return this._httpClient
      .post(`${environment.apiMain}/api/v1.0/projects`, project)
      .pipe(
        filter((pj) => !!pj),
        concatMap((pj: Project) =>
          this._httpClient
            .post(
              `${environment.apiMain}/api/v1.0/projects/${pj.projectId}/keywords`,
              keywords
            )
            .pipe(
              concatMap(() =>
                this._httpClient
                  .post(
                    `${environment.apiMain}/api/v1.0/projects/${pj.projectId}/platforms`,
                    warehouses
                  )
                  .pipe(map(() => pj))
              )
            )
        )
      );
  }

  deleteById(id: number) {
    return this.projects$.pipe(
      take(1),
      switchMap((projects) =>
        this._httpClient
          .delete(`${environment.apiMain}/api/v1.0/projects/${id}`)
          .pipe(
            map(() => {
              const index = projects.findIndex((item) => item.projectId === id);
              projects.splice(index, 1);
              this._projects.next(projects);

              return true;
            })
          )
      )
    );
  }
}
