<button  class="btn {{ buttonClass }}" (click)="onClick()" [disabled]="disabled">
  <img
    *ngIf="buttonImagePath"
    [src]="buttonImagePath"
    class="btn-img-icon me-1"
    alt="{{ buttonTitle }}"
  />
  <i *ngIf="buttonIconStart" class="{{ buttonIconStart }}"></i>
  {{ buttonTitle }}
  <i *ngIf="buttonIconEnd" class="{{ buttonIconEnd }}"></i>
</button>
