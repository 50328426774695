import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() buttonTitle: string;
  @Input() buttonClass: string;
  @Input() buttonImagePath: string;
  @Input() buttonIconEnd: string;
  @Input() buttonIconStart: string;
  @Output() btnClick = new EventEmitter();

  private _disabled: boolean;

  @Input()
  set disabled(value: boolean)
  {
    this._disabled = value;
  }
  
  get disabled()
  {
    return this._disabled;
  }

  constructor() {}

  ngOnInit(): void {
    // console.log('buttonTitle: ', this.buttonTitle);
  }

  /**
   * สำหรับเช็คฟังก์ชันเมื่อมีการกดปุ่ม
   */
  onClick() {
    this.btnClick.emit();
  }
}
