import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationItem } from '@app/theme/layout/admin/navigation/navigation';
import { ExcelService } from '@app/_services/excel.service';
import Projectdata from '../../../../../fake-data/project-data.json'

@Component({
  selector: 'app-breadcrumb-page',
  templateUrl: './breadcrumb-page.component.html',
  styleUrls: ['./breadcrumb-page.component.scss'],
})
export class BreadcrumbPageComponent implements OnInit {
  @Input() type: string;
  @Input() title: string;

  navigation: any;
  breadcrumbList: Array<any> = [];
  navigationList: any;
  activeLink: string = '';
  navTitle: string = '';

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    public nav: NavigationItem,
    private titleService: Title,
    private excelService: ExcelService
  ) {
    this.navigation = this.nav.get();
    this.setBreadcrumb();
    this.type = 'theme2';
  }
  ngOnInit() {
    this.navTitle = this.title;
  }

  setBreadcrumb() {
    let routerUrl: string;
    this._router.events.subscribe((router: any) => {
      if (router.urlAfterRedirects) {
        routerUrl = router.urlAfterRedirects;
        this.activeLink = router.url;
      } else if (router.routerEvent) {
        routerUrl = router.routerEvent.urlAfterRedirects;
        this.activeLink = router.routerEvent.url;
      }

      if (routerUrl && typeof routerUrl === 'string') {
        this.breadcrumbList.length = 0;
        this.filterNavigation(this.activeLink);
      }
    });
  }

  filterNavigation(activeLink) {
    let result: any;
    let title = 'Welcome';
    let setTitle = this.navTitle;
    let splitLink = activeLink.split('?');
    const currentLink = splitLink[0];

    this.navigation.forEach(function (a) {
      if (a.type === 'item' && 'url' in a && a.url === currentLink) {
        result = [
          {
            id: 'id' in a ? a.id : 0,
            url: 'url' in a ? a.url : false,
            title: a.title,
            breadcrumbs: 'breadcrumbs' in a ? a.breadcrumbs : true,
            type: a.type,
            buttonGroup: 'buttonGroup' in a ? a.buttonGroup : false,
            menuGroup: 'menuGroup' in a ? a.menuGroup : '',
            paramPage: 'paramPage' in a ? a.paramPage : '',
          },
        ];
        title = a.title;
      } else if (a.type === 'item' && 'children' in a) {
        a.children.forEach(function (b) {
          if (b.type === 'item' && 'url' in b && b.url === currentLink) {
            result = [
              {
                id: 'id' in a ? a.id : 0,
                url: 'url' in a ? a.url : false,
                title: a.title,
                breadcrumbs: 'breadcrumbs' in a ? a.breadcrumbs : true,
                type: a.type,
                buttonGroup: 'buttonGroup' in a ? a.buttonGroup : false,
                menuGroup: 'menuGroup' in a ? a.menuGroup : '',
                paramPage: 'paramPage' in a ? a.paramPage : '',
              },
              {
                id: 'id' in b ? b.id : 0,
                url: 'url' in b ? b.url : false,
                title: b.title ? b.title : setTitle,
                breadcrumbs: 'breadcrumbs' in b ? b.breadcrumbs : true,
                type: b.type,
                buttonGroup: 'buttonGroup' in b ? b.buttonGroup : false,
                menuGroup: 'menuGroup' in b ? b.menuGroup : '',
                paramPage: 'paramPage' in b ? b.paramPage : '',
              },
            ];
            title = b.title ? b.title : setTitle;
          } else {
            if (b.type === 'item' && 'children' in b) {
              b.children.forEach(function (c) {
                console.log(c);
                if (
                  c.type === 'item' &&
                  'url' in c &&
                  c.url === currentLink
                ) {
                  result = [
                    {
                      id: 'id' in b ? b.id : 0,
                      url: 'url' in b ? b.url : false,
                      title: b.title,
                      breadcrumbs: 'breadcrumbs' in b ? b.breadcrumbs : true,
                      type: b.type,
                      buttonGroup: 'buttonGroup' in b ? b.buttonGroup : false,
                      menuGroup: 'menuGroup' in b ? b.menuGroup : '',
                      paramPage: 'paramPage' in b ? b.paramPage : '',
                    },
                    {
                      id: 'id' in c ? c.id : 0,
                      url: 'url' in c ? c.url : false,
                      title: c.title ? c.title : setTitle,
                      breadcrumbs: 'breadcrumbs' in c ? c.breadcrumbs : true,
                      type: c.type,
                      buttonGroup: 'buttonGroup' in c ? c.buttonGroup : false,
                      menuGroup: 'menuGroup' in c ? c.menuGroup : '',
                      paramPage: 'paramPage' in c ? c.paramPage : '',
                    },
                  ];
                  title = c.title ? c.title : setTitle;
                }
              });
            }
          }
        });
      } else {
        if (a.type === 'group' && 'children' in a) {
          a.children.forEach(function (b) {
            if (b.type === 'item' && 'url' in b && b.url === currentLink) {
              result = [
                {
                  id: 'id' in b ? b.id : 0,
                  url: 'url' in b ? b.url : false,
                  title: b.title,
                  breadcrumbs: 'breadcrumbs' in b ? b.breadcrumbs : true,
                  type: b.type,
                  buttonGroup: 'buttonGroup' in b ? b.buttonGroup : false,
                  menuGroup: 'menuGroup' in b ? b.menuGroup : '',
                  paramPage: 'paramPage' in b ? b.paramPage : '',
                },
              ];
              title = b.title;
            } else {
              if (b.type === 'collapse' && 'children' in b) {
                b.children.forEach(function (c) {
                  if (
                    c.type === 'item' &&
                    'url' in c &&
                    c.url === currentLink
                  ) {
                    result = [
                      {
                        id: 'id' in b ? b.id : 0,
                        url: 'url' in b ? b.url : false,
                        title: b.title,
                        breadcrumbs: 'breadcrumbs' in b ? b.breadcrumbs : true,
                        type: b.type,
                        buttonGroup: 'buttonGroup' in b ? b.buttonGroup : false,
                        menuGroup: 'menuGroup' in b ? b.menuGroup : '',
                        paramPage: 'paramPage' in b ? b.paramPage : '',
                      },
                      {
                        id: 'id' in c ? c.id : 0,
                        url: 'url' in c ? c.url : false,
                        title: c.title,
                        breadcrumbs: 'breadcrumbs' in c ? c.breadcrumbs : true,
                        type: c.type,
                        buttonGroup: 'buttonGroup' in c ? c.buttonGroup : false,
                        menuGroup: 'menuGroup' in c ? c.menuGroup : '',
                        paramPage: 'paramPage' in c ? c.paramPage : '',
                      },
                    ];
                    title = c.title;
                  } else if (
                    c.type === 'item' &&
                    'url' in c &&
                    c.url !== currentLink &&
                    'children' in c
                  ) {
                    c.children.forEach(function (d) {
                      if (
                        d.type === 'item' &&
                        'url' in d &&
                        d.url === currentLink
                      ) {
                        result = [
                          {
                            id: 'id' in b ? b.id : 0,
                            url: 'url' in b ? b.url : false,
                            title: b.title,
                            breadcrumbs:
                              'breadcrumbs' in b ? b.breadcrumbs : true,
                            type: b.type,
                            buttonGroup:
                              'buttonGroup' in b ? b.buttonGroup : false,
                            menuGroup: 'menuGroup' in b ? b.menuGroup : '',
                            paramPage: 'paramPage' in b ? b.paramPage : '',
                          },
                          {
                            id: 'id' in c ? c.id : 0,
                            url: 'url' in c ? c.url : false,
                            title: c.title,
                            breadcrumbs:
                              'breadcrumbs' in c ? c.breadcrumbs : true,
                            type: c.type,
                            buttonGroup:
                              'buttonGroup' in c ? c.buttonGroup : false,
                            menuGroup: 'menuGroup' in c ? c.menuGroup : '',
                            paramPage: 'paramPage' in c ? c.paramPage : '',
                          },
                          {
                            id: 'id' in d ? d.id : 0,
                            url: 'url' in d ? d.url : false,
                            title: d.title,
                            breadcrumbs:
                              'breadcrumbs' in d ? d.breadcrumbs : true,
                            type: d.type,
                            buttonGroup:
                              'buttonGroup' in d ? d.buttonGroup : false,
                            menuGroup: 'menuGroup' in d ? d.menuGroup : '',
                            paramPage: 'paramPage' in d ? d.paramPage : '',
                          },
                        ];
                        title = d.title ? d.title : setTitle;
                      }
                    });
                  }
                });
              }
            }
          });
        }
      }
    });
    this.navigationList = result;
    // console.log('navigationList: ', this.navigationList);
    this.titleService.setTitle(title + ' | สำนักงานเศรษฐกิจการเกษตร');
  }

  clickHandler(btnType, breadcrumb) {
    console.log('clickHandler btnType: ', btnType);
    console.log('clickHandler breadcrumb: ', breadcrumb);
    switch (btnType) {
      case 'refresh':
        window.location.reload();
        break;
      case 'edit':
        let param = breadcrumb.paramPage ? breadcrumb.paramPage : '';
        this._router.navigate(['/' + breadcrumb.menuGroup + '/project/edit/']);
        break;
      case 'export':
        switch (breadcrumb.id) {
          case 'data-inside':
            this.excelService.exportAsExcelFile(Projectdata, 'Projectdata');
            break;
        }
        break;
      default:
        break;
    }
  }
}
