import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationResponse } from '@app/_models/types';
import { environment } from '@environments/environment';
import { BehaviorSubject, catchError, concatMap, filter, forkJoin, from, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';

import {
  SlmCategory,
  SlmUser,
  SlmUserResponse,
  SocialType,
  SocialTypeResponse,
} from './social-manage.model';
import { CategoryPagination, Warehouse, WarehouseFarmCategory, WarehousePagination } from './social.types';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root',
})
export class SocialManageService {

  private _apiPath = environment.apiMain + '/api/v1.0';

  private _categorys: BehaviorSubject<SlmCategory[] | null> = new BehaviorSubject(null);
  private _warehouseCategories: BehaviorSubject<SlmCategory[] | null> = new BehaviorSubject(null);

  private _users: BehaviorSubject<SlmUser[] | null> = new BehaviorSubject(null);
  private _user: BehaviorSubject<SlmUser | null> = new BehaviorSubject(null);

  private _socials: BehaviorSubject<SocialType[] | null> = new BehaviorSubject(null);

  private _warehouses: BehaviorSubject<Warehouse[] | null> = new BehaviorSubject(null);
  private _warehouse: BehaviorSubject<Warehouse | null> = new BehaviorSubject(null);

  private _warehousePagination: BehaviorSubject<WarehousePagination | null> = new BehaviorSubject(null);
  private _categoryPagination: BehaviorSubject<CategoryPagination | null> = new BehaviorSubject(null);

  private _warehouseSocial: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

  constructor(
    private _http: HttpClient
  ) { }

  get warehousePagination$(): Observable<WarehousePagination> {
    return this._warehousePagination.asObservable();
  }

  get categoryPagination$(): Observable<CategoryPagination> {
    return this._categoryPagination.asObservable();
  }

  //Warehouse
  get warehouse$(): Observable<Warehouse> {
    return this._warehouse.asObservable();
  }

  get warehouses$(): Observable<Warehouse[]> {
    return this._warehouses.asObservable();
  }


  get warehouseSocial$(): Observable<any[]> {
    return this._warehouseSocial.asObservable();
  }

  //Category
  get categorys$(): Observable<SlmCategory[]> {
    return this._categorys.asObservable();
  }

  //SocialType
  get socials$(): Observable<SocialType[]> {
    return this._socials.asObservable();
  }

  get warehouseCategories$(): Observable<SlmCategory[]> {
    return this._warehouseCategories.asObservable();
  }

  //Right
  get user$(): Observable<SlmUserResponse> {
    return this._user.asObservable();
  }

  get users$(): Observable<SlmUser[]> {
    return this._users.asObservable();
  }

  ////////////////////////////////////////////


  updateWarehouse(id, updates) {
    return this.warehouses$.pipe(
      take(1),
      switchMap((warehouses) =>
        this._http
          .patch(`${environment.apiMain}/api/v1.0/warehouses/${id}`, updates)
          .pipe(
            map((updatedWarehouse: Warehouse) => {

              const index = warehouses.findIndex(warehouse => warehouse.warehouseId == id);
              if (index !== -1) {
                warehouses[index] = { ...warehouses[index], ...updatedWarehouse };
                this._warehouses.next(warehouses);
              }
              return updatedWarehouse;
            })
          )
      )
    );
  }

  getWareHouse(search: string = "", page: number = 1, limit: number = 10, socialTypeId: number = 0, sort: string = 'createdTime', order: 'asc' | 'desc' | '' = 'asc', useStatus: string | null = null): Observable<{ pagination: WarehousePagination, warehouses: Warehouse[] }> {

    const params = {
      q: search,
      page: page.toString(),
      limit: limit.toString(),
      socialTypeId,
      sort,
      order
    };

    if (useStatus) {
      params['useStatus'] = useStatus;
    }

    return this._http.get<PaginationResponse>(this._apiPath + '/warehouses', {
      params: params
    }).pipe(
      switchMap(response => {
        const warehouses: Warehouse[] = response.data;

        if (!warehouses.length) {
          const ret: { pagination: WarehousePagination, warehouses: Warehouse[] } = {
            pagination: {
              length: response.totalItems,
              size: limit,
              page: response.currentPage - 1,
              lastPage: response.totalPages,
              startIndex: response.currentPage > 1 ? (response.currentPage - 1) * limit : 0,
              endIndex: Math.min(response.currentPage * limit, response.totalItems)
            },
            warehouses
          };

          this._warehousePagination.next(ret.pagination);
          this._warehouses.next(ret.warehouses);

          return of(ret)
        }

        // Fetch keywords and platforms for each project
        const requests = warehouses.map(warehouse => forkJoin([
          this._http.get<WarehouseFarmCategory[]>(`${environment.apiMain}/api/v1.0/warehouses/${warehouse.warehouseId}/warehouse_farm_categories`)
        ]));

        return forkJoin(requests).pipe(

          map((responses: [
            WarehouseFarmCategory[]
          ][]) => {

            warehouses.forEach((warehouse, index) => {
              warehouse.categories = responses[index][0];
            });

            const ret: { pagination: WarehousePagination, warehouses: Warehouse[] } = {
              pagination: {
                length: response.totalItems,
                size: limit,
                page: response.currentPage - 1,
                lastPage: response.totalPages,
                startIndex: response.currentPage > 1 ? (response.currentPage - 1) * limit : 0,
                endIndex: Math.min(response.currentPage * limit, response.totalItems)
              },
              warehouses
            };

            this._warehousePagination.next(ret.pagination);
            this._warehouses.next(ret.warehouses);

            return ret;
          })
        );
      })
    );
  }

  getWareHouseById(id): Observable<Warehouse | boolean> {
    return this._http.get<Warehouse>(`${environment.apiMain}/api/v1.0/warehouses/${id}`).pipe(

      switchMap(warehouse => {

        const requests = forkJoin([
          this._http.get<WarehouseFarmCategory[]>(`${environment.apiMain}/api/v1.0/warehouses/${warehouse.warehouseId}/warehouse_farm_categories`)
        ]);

        return forkJoin(requests).pipe(

          map((responses: [
            WarehouseFarmCategory[]
          ][]) => {

            warehouse.categories = responses[0][0];

            this._warehouse.next(warehouse);

            return warehouse;
          })
        );
      }),
      switchMap(warehouse => {

        if (!warehouse) {
          return throwError(() => 'Could not found Warehouse with id of ' + id + '!');
        }

        return of(warehouse)
      })
    );
  }

  deleteWareHouse(id: number): Observable<boolean> {
    return this.warehouses$.pipe(
      take(1),
      switchMap((warehouses) =>
        this._http
          .delete<boolean>(this._apiPath + '/warehouses/' + id)
          .pipe(
            map((isDeleted: boolean) => {
              // Find the index of the deleted category
              const index = warehouses.findIndex(
                (item) => item.warehouseId === id
              );

              // Delete the category
              warehouses.splice(index, 1);

              // Update the warehouses
              this._warehouses.next(warehouses);

              // Return the deleted status
              return isDeleted;
            }),
            /* filter(isDeleted => isDeleted),
            concatMap(() => this.deleteSocial(id).pipe(
              concatMap(() => this.deleteWareHouseCategory(id))
            )) */
          )
      )
    );
  }

  //Category

  // ignored pagination
  getCategorys(): Observable<SlmCategory[]> {
    return this._http.get(this._apiPath + '/farm_categories', {
      params: {
        q: '',
        page: '1',
        limit: 300
      }
    }).pipe(
      map((res: any) => res.data),
      tap((categorys: any) => {
        this._categorys.next(categorys);
      })
    );
  }

  getCategory(search: string = "", page: number = 1, limit: number = 10, sort: string = 'createdTime', order: 'asc' | 'desc' | '' = 'asc'): Observable<{ pagination: CategoryPagination, categories: SlmCategory[] }> {
    return this._http.get<PaginationResponse>(this._apiPath + '/farm_categories', {
      params: {
        q: search,
        page: page.toString(),
        limit: limit.toString(),
        sort,
        order
      }
    }).pipe(
      map(response => {

        const ret: { pagination: CategoryPagination, categories: SlmCategory[] } = {
          pagination: {
            length: response.totalItems,
            size: limit,
            page: response.currentPage - 1,
            lastPage: response.totalPages,
            startIndex: response.currentPage > 1 ? (response.currentPage - 1) * limit : 0,
            endIndex: Math.min(response.currentPage * limit, response.totalItems)
          },
          categories: response.data
        };

        this._categoryPagination.next(ret.pagination);
        this._categorys.next(ret.categories);

        return ret;

      })
    );
  }

  getCategoryByWarehouseId(id): Observable<any> {
    return this._http.get(this._apiPath + '/warehouses/' + id + '/warehouse_farm_categories').pipe(
      tap((category: any) => {
        this._warehouseCategories.next(category);
      })
    );
  }

  createCategory(data: SlmCategory): Observable<SlmCategory> {
    return this.categorys$.pipe(
      take(1),
      switchMap((categorys) =>
        this._http
          .post<SlmCategory>(this._apiPath + '/farm_categories', data)
          .pipe(
            map((newCategory) => {
              // Update the categorys with the new categorys
              this._categorys.next([...categorys, newCategory]);

              // Return the new categorys from observable
              return newCategory;
            })
          )
      )
    );
  }

  updateCategory(id: number, data: SlmCategory): Observable<SlmCategory> {
    return this.categorys$.pipe(
      take(1),
      switchMap((categorys) =>
        this._http
          .put<SlmCategory>(
            this._apiPath + '/farm_categories/' + id,
            data
          )
          .pipe(
            map((updatedCategory: SlmCategory) => {
              // Find the index of the updated categorys
              const index = categorys.findIndex(
                (item) => item.categoryFarmId === id
              );

              // Update the categorys
              categorys[index] = updatedCategory;

              // Update the categorys
              this._categorys.next(categorys);

              // Return the updated categorys
              return updatedCategory;
            })
          )
      )
    );
  }

  deleteCategory(id: number): Observable<boolean> {
    return this.categorys$.pipe(
      take(1),
      switchMap((categorys) =>
        this._http
          .delete<boolean>(this._apiPath + '/farm_categories/' + id)
          .pipe(
            map((isDeleted: boolean) => {
              // Find the index of the deleted category
              const index = categorys.findIndex(
                (item) => item.categoryFarmId === id
              );

              // Delete the category
              categorys.splice(index, 1);

              // Update the categorys
              this._categorys.next(categorys);

              // Return the deleted status
              return isDeleted;
            })
          )
      )
    );
  }

  getTypesocial(): Observable<SocialType[]> {
    return this._http.get(this._apiPath + '/social_type').pipe(
      map((res: any) => res.data),
      tap((socials: SocialType[]) => {
        this._socials.next(socials);
      })
    );
  }

  getSocial(warehouseId): Observable<any[]> {
    return this._http.get<any[]>(`${this._apiPath}/warehouses/${warehouseId}/warehouse_socials`).pipe(
      tap(v => {
        console.log(v);
        this._warehouseSocial.next(v);
        return v;
      })
    );
  }

  deleteSocial(warehouseId): Observable<any> {
    return this._http.delete(`${this._apiPath}/warehouses/${warehouseId}/warehouse_socials`).pipe(
      tap((v) => console.log("deleteSocial", v))
    )
  }

  saveSocial(warehouseId, socialList): Observable<any> {
    return this._http.post(`${this._apiPath}/warehouses/${warehouseId}/warehouse_socials`, socialList.map((social => {
      return { warehouseId, ...social }
    }))).pipe(
      tap((v) => console.log("saveSocial", v))
    )
    /* return from([{
      "warehouseId": warehouseId,
      "socialId": socialId
    }]).pipe(
      tap((v) => console.log("saveSocial", v))
    ) */

    /* [
      {
          "warehouseId": 1,
          "socialId": "socialId",
          "socialName": "socialName",
          "socialFans": "socialFans",
          "socialPicture": "socialPicture",
          "socialLink": "socialLink",
          "socialAbout": "socialAbout"
      }
  ] */
  }

  deleteWareHouseCategory(warehouseId): Observable<any> {
    return this._http.delete(`${this._apiPath}/warehouses/${warehouseId}/warehouse_farm_categories`).pipe(
      tap((v) => console.log("deleteWareHouseCategory", v))
    )
  }

  saveWareHouseCategory(warehouseId, categoryList): Observable<any> {
    return this._http.post(`${this._apiPath}/warehouses/${warehouseId}/warehouse_farm_categories`, categoryList.map(category => {
      return { warehouseId, categoryFarmId: category }
    })).pipe(
      tap((v) => console.log("saveWareHouseCategory", v))
    )
  }

  saveWareHouse(socialTypeId, warehouseName): Observable<any> {
    return this._http.post(`${this._apiPath}/warehouses`, {
      warehouseName,
      socialTypeId
    }).pipe(
      tap((v) => console.log("saveWareHouse", v))
    )
  }

  updateWareHouse(warehouseId, socialTypeId, warehouseName): Observable<any> {
    return this._http.put(`${this._apiPath}/warehouses/${warehouseId}`, {
      warehouseName,
      socialTypeId
    }).pipe(
      tap((v) => console.log("updateWareHouse", v))
    )
  }

  saveAll(warehouseName, socialTypeId, categoryList, socials = []) {
    console.log('array',socials)
    return this.saveWareHouse(socialTypeId, warehouseName)
      .pipe(
        concatMap(wh => this.deleteSocial(wh.warehouseId).pipe(
          concatMap(() => this.saveSocial(wh.warehouseId, socials).pipe(
            concatMap(() => this.deleteWareHouseCategory(wh.warehouseId).pipe(
              concatMap(() => this.saveWareHouseCategory(wh.warehouseId, categoryList).pipe(
                map(() => wh)
              ))
            ))
          ))
        ))
      )
  }


  updateAll(warehouseId, warehouseName, socialTypeId, categoryList, socials = []) {
    return this.updateWareHouse(warehouseId, socialTypeId, warehouseName).pipe(
      concatMap(wh => this.deleteSocial(wh.warehouseId).pipe(
        concatMap(() => this.saveSocial(wh.warehouseId, socials).pipe(
          concatMap(() => this.deleteWareHouseCategory(wh.warehouseId).pipe(
            concatMap(() => this.saveWareHouseCategory(wh.warehouseId, categoryList).pipe(
              map(() => wh)
            ))
          ))
        ))
      ))
    )
  }

  SearchCondition(params: any, body = {}): Observable<any> {
    return this._http.post(
      this._apiPath + 'overviews/top_mentions/' + body,
      {
        params,
        observe: 'response',
      }
    );
  }

}
