import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subject, first, map, takeUntil } from 'rxjs';
import { SocialManageService } from '@app/theme/pages/core/social-manage/social-manage.service';
import { SlmUserResponse } from '@app/theme/pages/core/social-manage/social-manage.model';
import { User, UserProject } from '@app/core/user/user.types';
import { UserService } from '@app/core/user/user.service';
import { Role } from '@app/_models';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class NavRightComponent implements OnInit, OnDestroy {

  loading = false;
  user: User;
  userProject: UserProject;

  visibleUserList: boolean;
  chatMessage: boolean;
  friendId: boolean;

  roleName = {
    [Role.Admin]: 'ผู้ดูแลระบบ',
    [Role.User]: 'ผู้ใช้งานทั่วไป',
    [Role.Manager]: 'ผู้บริหาร',
  };

  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(config: NgbDropdownConfig, private _userService: UserService, private _changeDetectorRef: ChangeDetectorRef) {
    config.placement = 'bottom-right';
    this.visibleUserList = false;
    this.chatMessage = false;
  }

  ngOnInit() {

    this.loading = true;
    
    this._userService.user$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((user: User) => {
        this.user = user;

        // Mark for check
        this._changeDetectorRef.markForCheck();
    });

    this._userService.userProject$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((userProject: UserProject) => {
        this.userProject = userProject;
    });
  }

  ngOnDestroy(): void {
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
  }

  onChatToggle(friend_id) {
    this.friendId = friend_id;
    this.chatMessage = !this.chatMessage;
  }
}
function res(value: User[], index: number): unknown {
  throw new Error('Function not implemented.');
}

