<div class="footer tw-w-full tw-overflow-hidden tw-max-h-full tw-bg-[#f9f9f9] tw-shadow-inner tw-py-1"
  *ngFor="let text of DataLogin">
  <div class="row tw-mt-4 tw-px-2">
    <div class="grid d-flex tw-flex-wrap tw-gap-2 tw-justify-center">
      <div class="font-ser">{{ text.footer_best_view }}</div>
      <div class="font-tel">{{ text.footer_resolution }}</div>
    </div>
  </div>
  <div class="row tw-mb-4 tw-px-2">
    <div class="grid d-flex tw-flex-wrap tw-gap-2 tw-justify-center">
      <div class="font-ser">{{ text.footer_main }}</div>
      <div class="font-tel">{{ text.footer_tel }}</div>
      <div class="font-sub">{{ text.footer_yo }}</div>
      <div class="font-sub">{{ text.footer_as }}</div>
    </div>
  </div>
</div>
