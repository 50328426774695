<ul class="navbar-nav">
  <li class="tw-p-1">
    <div class="tw-flex tw-flex-row">
      <div class="tw-flex tw-flex-row tw-items-center">
        <div class="tw-flex">
          <img src="./assets/images/header-backup.png" class="tw-w-20 max-xl:tw-w-14" alt="header-logo">
        </div>
        <div class="tw-flex tw-flex-col tw-items-center">
          <div class="tw-flex tw-flex-col">
            <label class="tw-text-xl tw-font-semibold tw-m-0 tw-text-white max-xl:tw-text-sm max-lg:tw-text-xs tw-mr-1">สำนักงานเศรษฐกิจการเกษตร</label>
            <label class="tw-text-sm tw-font-extralight tw-m-0 text-white max-lg:tw-text-xs">office of Agricultural Econmics</label>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-flex-row tw-items-center tw-mx-2">
        <div class="tw-flex tw-items-center tw-h-14 tw-p-3" style="border-left:2px solid #FFFFFF">
          <label class="tw-text-xl tw-text-white tw-m-0  max-xl:tw-text-xs tw-font-semibold max-lg:tw-text-xs " style="font-family: Kanit;">ระบบบริหารจัดการข้อมูลทางสังคม</label>
        </div>
      </div>
    </div>
    <!-- <a href="javascript:" class="full-screen"><i class="feather icon-maximize" style="color: #FFF;"></i></a> -->
  </li>
  <!-- <li class="nav-item dropdown" placement="auto">
    <div class="row">
      <p class="font-main">สำงานเศรษฐกิจการเกษตร</p>
      <span class="font-long">office of Agricultural Econmics</span>
      <span class="m-0 p-0"
        style="position: absolute; width: 0px; height: 48px; left: 370px; top: 9px; border-right: 1px solid #F5F5F5;">
      </span>
      <p class="font-main2">สำงานเศรษฐกิจการเกษตร</p>
    </div>
  </li> -->
  <!-- <li class="nav-item">
  </li> -->
</ul>
