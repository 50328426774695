<loading-bar></loading-bar>
<app-navigation class="pcoded-navbar" [ngClass]="{
    'navbar-collapsed': navCollapsed,
    'mob-open': navCollapsedMob
  }" (onNavCollapse)="this.navCollapsed = !this.navCollapsed" (onNavCollapsedMob)="navMobClick()"></app-navigation>
<app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light"
  (onNavCollapsedMob)="navMobClick()"></app-nav-bar>
<div class="pcoded-main-container">
  <div class="pcoded-wrapper">
    <div class="pcoded-content">
      <div class="pcoded-inner-content">
        <div class="main-body">
          <div class="page-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</div>


<!-- Note: ด้านล่างคือโค้ด body ชุดเดิม -->
<!-- <div class="pcoded-main-container">
  <div class="pcoded-wrapper">
    <div class="pcoded-content">
      <div class="pcoded-inner-content">
        <app-breadcrumb></app-breadcrumb>
        <div class="main-body">
          <div class="page-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->