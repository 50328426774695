import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { HistoryLogs, HistoryPagination } from './slm-history.types';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { PaginationResponse } from '@app/_models/types';

@Injectable({
  providedIn: 'root'
})
export class SlmHistoryService {

  private _historys: BehaviorSubject<HistoryLogs[] | null> = new BehaviorSubject(null);
  private _pagination: BehaviorSubject<HistoryPagination | null> = new BehaviorSubject(null);

  constructor(
    private _httpClient: HttpClient
  )
     { }

  get pagination$(): Observable<HistoryPagination> {
    return this._pagination.asObservable();
  }

  get historys$(): Observable<HistoryLogs[]> {
    return this._historys.asObservable();
  }

  get(search: string = "", page: number = 1, limit: number = 10, sort: string = 'createdTime', order: 'asc' | 'desc' | '' = 'desc'): Observable<{ pagination: HistoryPagination, historys: HistoryLogs[] }> {
    return this._httpClient.get<PaginationResponse>(`${environment.apiMain}/api/v1.0/logs`, {
      params: {
        q: search,
        page: page.toString(),
        limit: limit.toString(),
        sort,
        order
      }
    }).pipe(
      map(response => {

        const ret: { pagination: HistoryPagination, historys: HistoryLogs[] } = {
          pagination: {
            length: response.totalItems,
            size: limit,
            page: response.currentPage - 1,
            lastPage: response.totalPages,
            startIndex: response.currentPage > 1 ? (response.currentPage - 1) * limit : 0,
            endIndex: Math.min(response.currentPage * limit, response.totalItems)
          },
          historys: response.data
        };

        this._pagination.next(ret.pagination);
        this._historys.next(ret.historys);

        return ret;

      })
    );
  }
}
