import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Breadcrumb } from './breadcrumb.type';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _breadcrumb: BehaviorSubject<Breadcrumb | null> = new BehaviorSubject(
    null
  );

  private _hidebreadcrumb: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  constructor() {}

  /**
   * Setter & getter for breadcrumb
   *
   * @param value
   */
  set titleBreadcrumb(value: Breadcrumb) {
    this._breadcrumb.next(value);
  }

  get titleBreadcrumb$(): Observable<Breadcrumb> {
    return this._breadcrumb.asObservable();
  }

  set hideBreadcrumb(value: boolean) {
    this._hidebreadcrumb.next(value);
  }

  get hideBreadcrumb$(): Observable<boolean> {
    return this._hidebreadcrumb.asObservable();
  }
  

}
