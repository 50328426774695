<div class="breadcrumb-wrapper">
  <ng-container *ngFor="let breadcrumb of navigationList; let last = last">
    <div class="page-header" *ngIf="last && breadcrumb.breadcrumbs !== false">
      <div class="page-block">
        <div class="page-header-title">
          <div class="row">
            <div class="col-7">
              <i class="fa-sharp fa-solid fa-house me-2"></i>
              <ng-container *ngFor="let breadcrumb of navigationList; let last = last">
                <span class="me-2">{{ breadcrumb.title }}</span>
                <span *ngIf="!last" class="me-2">></span>
                <h4 *ngIf="last" class="breadcrumb-font">
                  {{ breadcrumb.title }}
                </h4>
              </ng-container>
            </div>
            <div *ngIf="last && breadcrumb.buttonGroup"
              class="col-5 d-flex flex-row justify-content-end align-items-end">
              <app-button *ngIf="breadcrumb.buttonGroup.refresh" buttonTitle="รีเฟรช" buttonClass="btn-base btn-blue"
                buttonImagePath="assets/icon/icon-refresh.png"
                (click)="clickHandler('refresh', breadcrumb)"></app-button>
              <app-button *ngIf="breadcrumb.buttonGroup.edit" buttonTitle="แก้ไขโปรเจกต์"
                buttonClass="btn-base btn-warning" buttonImagePath="assets/icon/icon-edit.png"
                (click)="clickHandler('edit', breadcrumb)"></app-button>
              <app-button *ngIf="breadcrumb.buttonGroup.export" buttonTitle="ส่งออก" buttonClass="btn-base btn-success"
                buttonImagePath="assets/icon/icon-export.png" (click)="clickHandler('export', breadcrumb)"></app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>