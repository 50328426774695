<ng-container *ngIf="show">
  <div class="tw-fixed tw-w-full tw-min-h-screen tw-flex tw-justify-center tw-items-center" style="z-index: 99999;">
    <div class="tw-flex tw-min-h-screen tw-w-full tw-items-center tw-justify-center tw-bg-gray-200  tw-bg-opacity-50 after:tw-bg-opacity-100 ">
      <div class="tw-flex tw-h-14 tw-w-14 tw-items-center tw-justify-center tw-rounded-full tw-bg-gradient-to-tr tw-from-indigo-500 tw-to-pink-500 tw-animate-spin">
        <div class="tw-h-9 tw-w-9 tw-rounded-full tw-bg-gray-200"></div>
      </div>
      <div class="tw-absolute tw-mt-24 tw-font-bold">
        กำลังโหลด ...
      </div>
    </div>
  </div>
</ng-container>
